import React, { useState, useEffect, useRef } from "react";
import Card from "../../components/Card";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactTable from "../../components/ReactTable";
import {
  getDefaultSizingCharts,
  updateSizingChart,
} from "../../services/products";
import {
  formatChartData,
  getChartLabel,
  formatClothingMeasurement,
  getErrorMessage,
  getMeasurementsList,
} from "../../helpers/functions";
import { CURRENT_CHART_PARAM, TABLE_MEASUREMENTS } from "../../types/types";
import { createColumnHelper } from "@tanstack/react-table";
import InputControl from "../../components/InputControl";
import BackButton from "../../components/BackButton";
import PlusIcon from "../../assets/svg/icon_plus.svg?react";
import { defaultSizingChart } from "../../helpers/defaultSizingChart";
import toast from "react-hot-toast";
import TrashIcon from "../../assets/svg/icon_trash.svg?react";
import { nanoid } from "nanoid";

export default function EditSizingChart() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [columns, setColumns] = useState([]);
  const [currentChart, setCurrentChart] = useState();
  const [currentMeasurement, setCurrentMeasurement] = useState(
    searchParams.get("defaultMeasurement") || TABLE_MEASUREMENTS.CM
  );
  const [chartData, setChartData] = useState();
  const [isSaving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState("");
  const fieldsRef = useRef([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCharts(id);
  }, [id]);

  useEffect(() => {
    if (currentChart) {
      const cols = getSizingChartColumns(currentChart);
      setColumns(cols);

      // Update fields with new measurements
      const fields = createChartFields(
        currentChart,
        getMeasurementsList(currentChart.sizes, true)
      );

      fieldsRef.current = fields;
    }
  }, [currentChart, currentMeasurement]);

  const getCharts = async (param) => {
    setLoading(true);
    await getDefaultSizingCharts()
      .then((res) => {
        if (res?.data) {
          const charts = res.data.items;
          setChartData(res.data);

          if (charts?.length > 0) {
            let selectedChart = param
              ? charts.find((chart) => chart.id === param)
              : charts[0];

            let indexedMeasurements = selectedChart.sizes.map((size) => {
              return { ...size, id: nanoid() };
            });
            selectedChart = { ...selectedChart, sizes: indexedMeasurements };
            setCurrentChart(selectedChart);

            /*Set default chart input fields & set initial values */
            const fields = createChartFields(
              selectedChart,
              getMeasurementsList(selectedChart.sizes, true)
            );
            fieldsRef.current = fields;

            const cols = getSizingChartColumns(selectedChart);
            setColumns(cols);
          }
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function getSizingChartColumns(data) {
    const columnHelper = createColumnHelper();

    const baseColumns = [
      columnHelper.accessor("title", {
        header: () => <span>Title</span>,
        cell: (info) => (
          <p className="capitalize min-w-[60px]">{info.row.original.title}</p>
        ),
      }),
    ];
    const sizes = data?.sizes;
    const formattedSizes = sizes.map((size) => {
      return columnHelper.accessor((row) => row, {
        id: `${size.name}-${size.id}`,
        cell: (info) => {
          const stats = info.row.original.measurements.find(
            (m) => m.id === size.id
          );
          const title = info.row.original.title;

          return (
            <div className="flex items-center text-sm min-w-[160px]">
              <InputControl
                name={`${title}-min`}
                className="mr-1 min-w-[80px] max-w-[100px]"
                defaultValue={formatClothingMeasurement({
                  val: stats?.min,
                  current: currentMeasurement,
                  addUnits: false,
                  originalMeasurement: chartData?.unit,
                })}
                onChange={(e) =>
                  updateChartField(title, "min", e.target.value, size?.id)
                }
              />
              <p>{currentMeasurement}</p>
              <p className="mx-2">-</p>
              <InputControl
                name={`${title}-max`}
                className="mr-1 min-w-[80px] max-w-[100px]"
                defaultValue={formatClothingMeasurement({
                  val: stats?.max,
                  current: currentMeasurement,
                  addUnits: false,
                  originalMeasurement: chartData?.unit,
                })}
                onChange={(e) =>
                  updateChartField(title, "max", e.target.value, size?.id)
                }
              />
              <p>{currentMeasurement}</p>
            </div>
          );
        },
        header: () => (
          <div className="flex items-center">
            <InputControl
              name={`${size.name}-input`}
              className="pr-2"
              inputClassName="bg-white"
              defaultValue={size.name}
              onChange={(e) => updateTitleField(e.target.value, size?.id)}
            />{" "}
            <button
              title="Delete column"
              onClick={() => onDeleteColumn(size?.id)}
            >
              <TrashIcon className="w-5 h-5 hover:text-red-500" />
            </button>
          </div>
        ),
      });
    });
    return [...baseColumns, ...formattedSizes];
  }

  function createChartFields(selectedChart, measurements) {
    const sizes = selectedChart.sizes;
    let fields = [];

    sizes.forEach((size) => {
      let body = { name: size?.name, id: size?.id };
      measurements.forEach((measurement) => {
        if (size[measurement] !== null) {
          body[measurement] = {
            max: formatClothingMeasurement({
              val: size[measurement]?.max,
              current: currentMeasurement,
              addUnits: false,
              originalMeasurement: chartData?.unit,
            }),
            min: formatClothingMeasurement({
              val: size[measurement]?.min,
              current: currentMeasurement,
              addUnits: false,
              originalMeasurement: chartData?.unit,
            }),
          };
        } else {
          body[measurement] = null;
        }
      });
      fields.push(body);
    });
    return fields;
  }
  const updateChartField = (measurement, range, value, currentIndex) => {
    let updatedFields = fieldsRef.current.map((field) => {
      if (currentIndex === field.id) {
        let tempField = field;
        tempField[measurement][range] = value;
        return tempField;
      }

      return field;
    });

    fieldsRef.current = updatedFields;
  };

  const updateTitleField = (value, currentIndex) => {
    let updatedFields = fieldsRef.current.map((field) => {
      if (currentIndex === field.id) {
        return { ...field, name: value };
      }
      return field;
    });

    fieldsRef.current = updatedFields;
  };

  const toggleMeasurement = () => {
    if (currentMeasurement === TABLE_MEASUREMENTS.INCHES) {
      setCurrentMeasurement(TABLE_MEASUREMENTS.CM);
    } else {
      setCurrentMeasurement(TABLE_MEASUREMENTS.INCHES);
    }
  };

  const onSaveChart = async () => {
    // If saving for the first time, have a confirmation window that the fit recommendation will be turned on.

    setSaving(true);
    setSavingError("");
    let fields = fieldsRef.current;

    const updatedItems = chartData.items.map((chart) => {
      if (chart.id === currentChart.id) {
        return { id: currentChart.id, sizes: [...fields] };
      }
      return chart;
    });

    const body = {
      items: updatedItems,
      unit: currentMeasurement,
    };

    //const body = defaultSizingChart;
    await updateSizingChart(body)
      .then((res) => {
        if (res?.data) {
          toast.success("Table saved.");
          navigate(`/sizing-charts?${CURRENT_CHART_PARAM}=${id}`);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setSavingError(message);
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const onAddColumn = () => {
    const sampleItem = currentChart?.sizes[0];
    const sampleKeys = Object.keys(sampleItem);
    const emptyColumn = {};

    sampleKeys.forEach((key) => {
      if (sampleItem[key] === null) {
        emptyColumn[key] = null;
      } else if (key === "name") {
        emptyColumn[key] = "New Column";
      } else {
        emptyColumn[key] = { min: 0, max: 0 };
      }
    });

    // Update chart
    const updatedChart = {
      ...currentChart,
      sizes: [...currentChart.sizes, emptyColumn],
    };
    setCurrentChart(updatedChart);
  };

  const onDeleteColumn = (id) => {
    // Must have at least 1 column
    const numCols = currentChart.sizes.length;

    if (id && numCols > 1) {
      // Remove from chart
      const updatedChart = {
        ...currentChart,
        sizes: currentChart.sizes.filter((size) => size.id !== id),
      };
      setCurrentChart(updatedChart);
    } else {
      toast.error("You must have at least 1 column");
    }
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-10">
        <div className="flex items-center">
          <BackButton className="mr-2" defaultPath="/sizing-charts" />
          <h1 className="page-title mb-0">Edit Sizing Chart</h1>
        </div>

        <button
          className={`btn-solid ${isSaving ? "loading" : ""}`}
          onClick={onSaveChart}
        >
          {!isSaving ? "Save" : "Saving..."}
        </button>
      </div>
      <ErrorMessage message={savingError} className="mb-4" />
      {!isLoading && error ? (
        <ErrorMessage message={error} />
      ) : (
        <Card>
          <div className="flex flex-col md:flex-row items-center justify-between mb-6">
            <p className="text-xl font-semibold mb-2 md:mb-0">
              {getChartLabel(id)}
            </p>
            <div className="flex items-center">
              <button
                className="grid grid-cols-2 bg-white-smoke p-2 rounded-av"
                onClick={toggleMeasurement}
              >
                <div
                  className={`${
                    currentMeasurement === TABLE_MEASUREMENTS.INCHES
                      ? "bg-primary text-white"
                      : ""
                  } p-1 rounded-av`}
                >
                  in
                </div>
                <div
                  className={`${
                    currentMeasurement === TABLE_MEASUREMENTS.CM
                      ? "bg-primary text-white"
                      : ""
                  } p-1 rounded-av`}
                >
                  cm
                </div>
              </button>
              <button className="ml-4 btn-solid" onClick={onAddColumn}>
                <PlusIcon className="btn-prefix-icon" />
                Add Column
              </button>
            </div>
          </div>
          <ReactTable
            columns={columns}
            data={formatChartData(currentChart)}
            isLoading={isLoading}
            totalPages={1}
            emptyMessage="No chart data found..."
          />
        </Card>
      )}
    </div>
  );
}
