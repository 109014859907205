import { generate } from "@bramus/pagination-sequence";

const PaginationEntry = ({
  value,
  onEntryClick = null,
  label = null,
  title = null,
  isCurrent = false,
  isDisabled = false,
  ...props
}) => {
  label ??= value;
  title ??= `Go to page ${value}`;

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    e.target.blur();

    if (onEntryClick) {
      onEntryClick(value);
    }
  };

  if (value == "…") {
    return (
      <li data-pagination-ellipsis {...props}>
        <span>{label}</span>
      </li>
    );
  }

  if (isDisabled) {
    return (
      <li data-pagination-disabled {...props}>
        <span>{label}</span>
      </li>
    );
  }

  if (isCurrent) {
    props["data-pagination-current"] = true;
  }

  return (
    <li {...props}>
      <p title={title} className="pag-link cursor-pointer" onClick={onClick}>
        {label}
      </p>
    </li>
  );
};

const Pagination = ({
  curPage,
  numPages,
  numPagesAtEdges = 2,
  numPagesAroundCurrent = 2,
  onEntryClick = null,
}) => {
  const sequence = generate(
    curPage,
    numPages,
    numPagesAtEdges,
    numPagesAroundCurrent
  );

  return (
    <ul className="pagination my-4 mx-auto">
      <PaginationEntry
        data-pagination-first
        onEntryClick={onEntryClick}
        value={1}
        title="Go to First Page"
        label="&laquo;"
        isDisabled={curPage === 1}
      />
      <PaginationEntry
        data-pagination-prev
        onEntryClick={onEntryClick}
        value={curPage - 1}
        title="Go to Previous Page"
        label="&lsaquo;"
        isDisabled={curPage === 1}
      />
      {sequence.map((val, idx) => (
        <PaginationEntry
          key={`page-${val == "…" ? `…-${idx}` : val}`}
          onEntryClick={onEntryClick}
          value={val}
          isCurrent={val == curPage}
        />
      ))}
      <PaginationEntry
        data-pagination-next
        onEntryClick={onEntryClick}
        value={curPage + 1}
        title="Go to Next Page"
        label="&rsaquo;"
        isDisabled={curPage === numPages}
      />
      <PaginationEntry
        data-pagination-next
        onEntryClick={onEntryClick}
        value={numPages}
        title="Go to Last Page"
        label="&raquo;"
        isDisabled={curPage === numPages}
      />
    </ul>
  );
};

export default Pagination;
