export default function StepsComponent({ currentStep, steps }) {
  const getStepStyle = (step) => {
    if (step === currentStep) {
      return "bg-primary w-5";
    }
    if (step > currentStep) {
      return "bg-slate-300 w-2.5";
    } else {
      return "bg-primary w-2.5";
    }
  };
  return (
    <div className="flex items-center gap-2">
      {steps.map((step, index) => {
        return (
          <div
            key={index}
            className={`h-2.5 ${getStepStyle(step.step)} rounded-full`}
          ></div>
        );
      })}
    </div>
  );
}
