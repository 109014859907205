import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const getAnalytics = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.ANALYTICS}`,
    method: "get",
  });
};

export const getTryOnAnalytics = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.TRYON_ANALYTICS}`,
    data,
    method: "post",
  });
};

export const getTopProducts = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.TOP_PRODUCTS}`,
    method: "get",
  });
};
