import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Loader from "../../components/Loader";
import { useAvStore } from "../../store";
import EditProfileModal from "./modals/EditProfileModal";
import { getProfile } from "../../services/vendor";
import { getErrorMessage } from "../../helpers/functions";
import ErrorMessage from "../../components/ErrorMessage";
import { USER_ROLE } from "../../types/types";
import AdminProfileDetailsCard from "./AdminProfileDetailsCard";
import EditContactInfoModal from "./modals/EditContactInfoModal";

export default function Profile() {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditContactModalOpen, setEditContactModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(true);
  const user = useAvStore((state) => state.user);

  useEffect(() => {
    // Always re-fetch to ensure that profile updates if the profile has been updated.
    getVendorProfile();
  }, []);

  const getVendorProfile = async () => {
    setError("");
    setLoading(true);

    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <h1 className="page-title">Profile</h1>
      {isLoading && <Loader />}
      {!isLoading && error && <ErrorMessage message={error} />}
      {user && !error && !isLoading && (
        <>
          {user.role === USER_ROLE.VENDOR_SUPER ? (
            <>
              <div className="grid md:grid-cols-2 gap-10 items-start">
                <Card className="flex flex-col items-center justify-start mb-auto">
                  <div className="rounded-full overflow-hidden aspect-square max-w-[160px] w-full shadow-sm border-2">
                    {user?.profileLogoUrl ? (
                      <img
                        loading="lazy"
                        src={user.profileLogoUrl}
                        alt="avatar"
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <div className="flex items-center justify-center bg-slate-100 w-full h-full">
                        <p className="uppercase text-2xl text-center">
                          {user?.businessName}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="p-2 text-center w-full">
                    <h2 className="text-lg font-medium text-slate-700">
                      {user?.businessName}
                    </h2>
                    <p className="link">{user?.website}</p>
                  </div>
                </Card>

                {/* Details Section */}
                <Card>
                  <div className="flex flex-col gap-4">
                    <div>
                      <h2 className="page-subtitle">Business Details</h2>{" "}
                      <button
                        className="text-sm text-primary cursor-pointer ml-auto"
                        onClick={() => setEditModalOpen(true)}
                      >
                        Need your info changed?
                      </button>
                    </div>
                    <div>
                      <h3>Joined</h3>
                      <p>{user?.createdDate}</p>
                    </div>
                    <div>
                      <h3>Address</h3>
                      {user?.address || "--"}
                    </div>
                    <div>
                      <h3>Country</h3>
                      {user?.country || "--"}
                    </div>
                    <div>
                      <h3>Estimated Revenue</h3>
                      <p>${user?.estimatedRevenue}</p>
                    </div>

                    <hr />

                    <div className="flex items-center justify-between">
                      <h2 className="page-subtitle">Contact Details</h2>
                      <button
                        className="btn-solid btn-mini"
                        onClick={() => setEditContactModalOpen(true)}
                      >
                        Edit
                      </button>
                      <EditContactInfoModal
                        isOpen={isEditContactModalOpen}
                        onClose={() => setEditContactModalOpen(false)}
                        user={user}
                      />
                    </div>
                    <div>
                      <p className="font-semibold">
                        {user?.firstName} {user?.lastName}
                      </p>
                      <p>{user?.email}</p>
                    </div>

                    <div>
                      <h3>Phone Number</h3>
                      <p>{user?.phoneNumber}</p>
                    </div>
                  </div>
                </Card>
              </div>

              <EditProfileModal
                isOpen={isEditModalOpen}
                profileDetails={user}
                onClose={() => setEditModalOpen(false)}
              />
            </>
          ) : (
            <AdminProfileDetailsCard user={user} />
          )}
        </>
      )}
    </div>
  );
}
