export const apiRoutes = {
  LOGIN: "api/v1/Vendors/login",
  REFRESH_TOKEN: "api/v1/Accounts/refreshtoken",
  MFA_LOGIN: "api/v1/Accounts/mfalogin",
  RESEND_MFA_CODE: "api/v1/Accounts/resendmfacode",
  REGISTER_ADMIN: "api/v1/Vendors/register/vendoradmin",
  TOGGLE_MFA: "api/v1/Accounts/togglemfa",

  TUTORIAL: "api/v1/Vendors/read/tutorial",

  SET_PSWD: "api/v1/Accounts/setpassword",
  CHANGE_PSWD: "api/v1/Accounts/changepassword", // in portal
  FORGOT_PSWD: "api/v1/Accounts/forgotpassword", // forgot pswd flow
  RESET_PSWD: "api/v1/Accounts/resetpassword", // forgot pswd flow
  RESEND_FORGOT_PSWD_CODE: "api/v1/Accounts/resendforgotpasswordcode",

  RESEND_INVITE: "api/v1/Accounts/resendinvites",

  PROFILE: "api/v1/Vendors/profile",
  CONTACT: "api/v1/Accounts/contact",

  /* Feedback */
  FEEDBACK: "api/v1/Feedbacks",
  FEEDBACK_CATEGORIES: "api/v1/Feedbacks/categories",

  /* SETTINGS */
  COUNTRIES: "api/v1/Settings/countries",

  /* Vendor team admins*/
  TEAM: "api/v1/Vendors/team",
  DELETE_TEAM_MEMBER: "api/v1/Vendors",

  /* Products */
  UPLOAD_PRODUCTS: "api/v1/Products/upload",
  PRODUCTS_INSTANCES: "api/v1/Products/instances",
  PRODUCTS: "api/v1/Products",
  DEFAULT_CHART: "api/v1/Products/vendor/sizingchart",
  UPDATE_SIZING_CHART: "api/v1/Products/vendorsizingchart",
  TOGGLE_FIT: "api/v1/Products/sizingchart/toggle/fit",

  /* ANALYTICS */
  ANALYTICS: "api/v1/Vendors/analytics",
  TRYON_ANALYTICS: "api/v1/Vendors/analyticsbydate/tryon",
  TOP_PRODUCTS: "api/v1/Products/analytics/topproducts",
};
