import CustomDropdownMenu from "../components/CustomDropdownMenu";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearSession, useAvStore } from "../store";
import MenuIcon from "../assets/svg/icon_hamburger-menu.svg?react";
import LogoutIcon from "../assets/svg/icon_logout.svg?react";
import TutorialModal from "./modals/TutorialModal";

export default function Topbar({ openSidebar }) {
  const navigate = useNavigate();
  const user = useAvStore((state) => state.user);
  const [isTutorialShowing, setTutorialShowing] = useState(false);

  useEffect(() => {
    if (user?.hasViewedTutorial === false) {
      setTutorialShowing(true);
    }
  }, [user]);

  const onLogout = () => {
    clearSession();
    navigate("/signin");
  };

  const dropdownItems = [
    {
      render: () => (
        <div className="flex items-center">
          <div className="rounded-full overflow-hidden aspect-square max-w-[40px] w-full shadow-sm border-2 flex-shrink-0 mr-2">
            <div className="flex items-center justify-center bg-white w-full h-full">
              <p className="uppercase text-lg">{`${user?.firstName[0]}${user?.lastName[0]}`}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="font-medium">
              {user ? `${user?.firstName} ${user?.lastName}` : ""}
            </p>
            <p
              className="text-sm text-ellipsis w-[90%] overflow-hidden"
              title={user?.email}
            >
              {user?.email}
            </p>
          </div>
        </div>
      ),
    },
    { label: "divider" },
    {
      label: "Profile",
      onClick: () => {
        navigate("/profile");
      },
    },
    {
      label: "Security Settings",
      onClick: () => {
        navigate("/security-settings");
      },
    },
    { label: "divider" },
    {
      label: "Tutorial",
      onClick: () => {
        setTutorialShowing(true);
      },
    },
    { label: "divider" },
    {
      label: (
        <div className="flex items-center">
          <LogoutIcon className="w-6 h-6 mr-2" /> Logout
        </div>
      ),
      onClick: () => onLogout(),
    },
  ];

  return (
    <div className="fixed top-0 left-0 right-0 w-full px-4 py-2 shadow-sm h-[70px] bg-white border-l z-20">
      <div className="container flex justify-between">
        <button className="md:hidden" onClick={openSidebar}>
          <MenuIcon className="w-7 h-7" />
        </button>
        <CustomDropdownMenu items={dropdownItems} className="min-w-[220px]">
          <button
            type="button"
            className="rounded-full overflow-hidden aspect-square max-w-[50px] w-full shadow-sm border-2 ml-auto group outline-none"
          >
            <div className="flex items-center justify-center group-hover:bg-slate-100 bg-white w-full h-full">
              <p className="uppercase text-xl">
                {user ? `${user?.firstName[0]}${user?.lastName[0]}` : ""}
              </p>
            </div>
          </button>
        </CustomDropdownMenu>
      </div>
      <TutorialModal
        isOpen={isTutorialShowing}
        onClose={() => setTutorialShowing(false)}
        hasViewedTutorial={user?.hasViewedTutorial}
      />
    </div>
  );
}
