import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Outlet } from "react-router-dom";

const TABLET_BREAKPOINT = 768;

export default function HomeLayout() {
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  const [isMobileView, setMobileView] = useState();
  const sidebarRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleOffClick);
    return () => document.removeEventListener("mousedown", handleOffClick);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOffClick = (e) => {
    if (isMobileView)
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setSidebarOpenMobile(false);
      }
  };

  const handleResize = () => {
    if (window.innerWidth >= TABLET_BREAKPOINT) {
      setMobileView(false);
      if (sidebarOpenMobile) setSidebarOpenMobile(false);
    } else {
      setMobileView(true);
    }
  };

  return (
    <div className="relative flex bg-white-smoke w-full min-h-screen">
      <div ref={sidebarRef}>
        <Sidebar
          className={`flex flex-col fixed top-0 left-0 bottom-0 md:translate-x-0 z-40 ${
            sidebarOpenMobile ? "" : "-translate-x-full"
          } h-screen overflow-y-auto max-w-[240px] w-full bg-white shadow-sm flex-shrink-0 transition-all duration-300`}
          isMobileView={isMobileView}
          closeSidebar={() => {
            if (isMobileView) {
              setSidebarOpenMobile(false);
            }
          }}
        />
      </div>
      <main className="md:pl-[240px] w-full pt-[70px]">
        <Topbar openSidebar={() => setSidebarOpenMobile(true)} />
        <div className="p-10">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
