import React from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";
import Loader from "./Loader";
import Pagination from "./Pagination";
import { useSearchParams } from "react-router-dom";

export default function ReactTable({
  data,
  columns,
  className,
  isLoading,
  totalPages = 1,
  emptyMessage = "No items found...",
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={`flex flex-col w-full overflow-hidden ${className || ""}`}>
      <div className="flex flex-col overflow-x-auto rounded-av">
        <table>
          <thead className="bg-slate-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="py-2 px-4 font-normal text-sm text-start"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {!isLoading && data.length !== 0 && (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b last:border-b-0">
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="py-2 px-4">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {isLoading && (
          <div className="min-h-[100px] flex items-center justify-center">
            <Loader />
          </div>
        )}
        {!isLoading && data.length === 0 && (
          <p className="min-h-[100px] flex items-center justify-center">
            {emptyMessage}
          </p>
        )}
      </div>
      {totalPages > 1 && !isLoading && (
        <Pagination
          curPage={parseInt(searchParams?.get("page")) || 1}
          numPages={totalPages}
          onEntryClick={(val) => {
            searchParams.set("page", val);
            setSearchParams(searchParams);
          }}
        />
      )}
    </div>
  );
}
