import { useRef, useCallback, useEffect } from "react";

export default function useAbortController(id) {
  const abortRef = useRef({});

  useEffect(() => {
    return () => {
      if (abortRef.current) {
        Object.keys(abortRef.current).forEach((key) => {
          if (abortRef.current[key] !== undefined) {
            abortRef.current[key].abort();
          }
        });
      }
    };
  }, []);

  const getSignal = useCallback(() => {
    if (abortRef.current && abortRef.current[id] !== undefined) {
      abortRef.current[id].abort();
      abortRef.current[id] = new AbortController();
    } else {
      abortRef.current[id] = new AbortController();
    }
    return abortRef.current[id].signal;
  }, []);

  return getSignal;
}
