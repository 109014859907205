import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "../assets/svg/icon_home.svg?react";
import ProductIcon from "../assets/svg/icon_shirt.svg?react";
import FeedbackIcon from "../assets/svg/icon_thumbs-up.svg?react";
import WrenchIcon from "../assets/svg/icon_wrench.svg?react";
import TeamIcon from "../assets/svg/icon_group.svg?react";
import CloseIcon from "../assets/svg/icon_close.svg?react";
import Logo from "../assets/svg/avease_logo_color_no-text.svg?react";

const MenuItem = ({
  path,
  name,
  icon,
  className,
  sublink,
  active,
  onClick,
}) => {
  const location = useLocation();
  const isActive = active
    ? active
    : path !== "/" && location?.pathname.includes(path)
    ? true
    : false || location?.pathname === path;

  return (
    <Link
      className={`${isActive ? "text-primary" : ""} w-full`}
      to={path}
      onClick={onClick}
    >
      <div
        className={`sidebar-menu-item ${sublink ? "my-2 pl-7" : ""} ${
          className || ""
        } ${isActive && !sublink ? " bg-white-smoke rounded-lg" : ""}`}
      >
        <div
          className={`w-6 h-6 mr-2 inline-flex flex-shrink-0 ${
            isActive ? "text-primary" : ""
          }`}
        >
          {icon}
        </div>
        {name}
      </div>
    </Link>
  );
};

const Submenu = ({ title, icon, path, paths, children }) => {
  const location = useLocation();
  const isActive = paths.some((path) => path === location?.pathname);

  return (
    <div>
      <MenuItem
        path={path}
        name={title}
        icon={icon}
        className={isActive ? "pb-1" : ""}
        active={isActive}
      />
      {isActive && <div>{children}</div>}
    </div>
  );
};

export default function Sidebar({ className, closeSidebar, isMobileView }) {
  const navigate = useNavigate();
  const onItemClick = () => {
    closeSidebar();
  };

  return (
    <div className={className}>
      {isMobileView && (
        <button
          className="absolute top-4 right-4 w-5 h-5"
          onClick={closeSidebar}
        >
          <CloseIcon />
        </button>
      )}
      <div className="flex items-center h-[70px] border-b">
        <div className="flex items-end ml-8">
          <Logo className="max-w-[70px] text-primary mr-2" />
          <p className="font-semibold">Vendor Portal</p>
        </div>
      </div>
      <nav className="flex flex-col mt-4 ml-4 flex-grow">
        <MenuItem
          path={"/"}
          name="Dashboard"
          icon={<DashboardIcon />}
          onClick={onItemClick}
        />
        <MenuItem
          path={"/products"}
          name="Product Feed"
          icon={<ProductIcon />}
          onClick={onItemClick}
        />
        <hr className="m-4" />
        <MenuItem
          path={"/send-feedback"}
          name="Send Feedback"
          icon={<FeedbackIcon />}
          onClick={onItemClick}
        />
        <Submenu
          title="Setup"
          path="/widget-integration"
          icon={<WrenchIcon />}
          paths={[
            "/widget-integration",
            "/product-feed-formatting",
            "/product-data-upload",
            "/sizing-charts",
          ]}
        >
          <MenuItem
            path={"/widget-integration"}
            name="Widget Integration"
            sublink
            onClick={onItemClick}
          />
          <MenuItem
            path={"/product-feed-formatting"}
            name="Feed Formatting"
            sublink
            onClick={onItemClick}
          />
          <MenuItem
            path={"/product-data-upload"}
            name="Upload Product Data"
            sublink
            onClick={onItemClick}
          />
          <MenuItem
            path={"/sizing-charts"}
            name="Sizing Charts"
            sublink
            onClick={onItemClick}
          />
        </Submenu>
        <MenuItem
          path={"/team"}
          name="Team"
          icon={<TeamIcon />}
          onClick={onItemClick}
        />
      </nav>
    </div>
  );
}
