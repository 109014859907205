import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import RoutesList from "./routes";
import { checkAuthentication, useAvStore } from "./store";
import { getProfile } from "./services/vendor";

function App() {
  useEffect(() => {
    const isAuthenticated = checkAuthentication();
    if (isAuthenticated) {
      getVendorProfile();
    }
  }, []);

  const getVendorProfile = async () => {
    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Toaster />
      <RoutesList />
    </>
  );
}

export default App;
