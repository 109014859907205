import React, { useRef, useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { saveSession, useAvStore } from "../../store";
import BackButton from "../../components/BackButton";
import { mfaLogin, resendMfaCode } from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import ErrorMessage from "../../components/ErrorMessage";
import toast from "react-hot-toast";
import { getProfile } from "../../services/vendor";
import { useDebouncedCallback } from "use-debounce";

export default function MFA() {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const codesRef = useRef([]);
  const location = useLocation();
  const [isValid, setValid] = useState(false);
  const [isResending, setResending] = useState(false);

  const debouncedCodeChange = useDebouncedCallback(
    // function
    (e, index) => {
      onCodeChange(e, index);
    },
    // delay in ms
    0
  );

  const onCodeChange = (e, index) => {
    const val = e.target.value;
    let newCode = code;
    // On backspace
    if (e.keyCode == 8) {
      if (!newCode[index]) {
        goBackwards(index);
      }
    } else if (e.keyCode == 37) {
      // On left arrow
      goBackwards(index);
    } else if (e.keyCode == 39) {
      // On right arrow
      goForwards(index);
    } else if (
      e.keyCode !== 9 &&
      e.keyCode !== 17 &&
      e.keyCode !== 67 &&
      val !== "" &&
      nextIsEmpty(index, newCode)
    ) {
      /* If key code is not tab/ctrl/c and we are not replacing a value, and next input is empty, move to right */
      goForwards(index);
    }
    newCode[index] = val;
    setValid(!newCode.some((c) => c === ""));
    setCode(newCode);
  };

  function nextIsEmpty(index, currentCode) {
    if (index + 1 < code.length) {
      if (currentCode[index + 1] === "") {
        return true;
      }
    }
    return false;
  }

  function goBackwards(index) {
    if (index - 1 > 0) {
      codesRef.current[index - 1].focus();
    } else {
      codesRef.current[0].focus();
    }
  }

  function goForwards(index) {
    if (index + 1 < code.length - 1) {
      codesRef.current[index + 1].focus();
    } else {
      codesRef.current[code.length - 1].focus();
    }
  }

  const onConfirmValidate = async () => {
    setLoading(true);
    setError("");

    await mfaLogin({
      email: location.state?.email,
      password: location.state?.password,
      code: code.join(""),
    })
      .then((res) => {
        if (res) {
          const loginInfo = res.data?.loginResponse;

          if (loginInfo) {
            saveSession({
              access_token: loginInfo.accessToken,
              refresh_token: loginInfo.refreshToken,
            });
            getVendorProfile();
            navigate("/");
          }
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getVendorProfile = async () => {
    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOnPaste = (e, position) => {
    e.preventDefault();
    const clipboardData = e.clipboardData?.getData("Text");
    let newCode = code;

    if (clipboardData.length > 0) {
      let donePasting = false;
      let currentPosition = position;
      let clipboardPosition = 0;

      while (currentPosition < code.length && !donePasting) {
        if (clipboardPosition > clipboardData.length - 1) {
          donePasting = true;
        } else {
          newCode[currentPosition] = clipboardData[clipboardPosition];
          codesRef.current[currentPosition].focus();
          codesRef.current[currentPosition].value =
            clipboardData[clipboardPosition];
        }
        currentPosition += 1;
        clipboardPosition += 1;
      }
    }
    setCode(newCode);
  };

  const onResendClick = async () => {
    setResending(true);
    setError("");

    await resendMfaCode({
      email: location.state?.email,
    })
      .then((res) => {
        if (res) {
          toast.success("Code sent");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setResending(false);
      });
  };

  return (
    <AuthLayout>
      <div className="relative flex flex-col text-center">
        <BackButton className="absolute top-2 -left-6" defaultPath="/signin" />
        <h1 className="text-4xl lg:text-5xl font-semibold mb-6 text-center lg:text-start ml-3">
          Confirmation
        </h1>
        <p>
          A code has been sent to your email ({location.state?.email}) for
          verification. Please enter it below.
        </p>
        <div className="flex items-center justify-center mt-10 mb-6 gap-4">
          {code.map((val, index) => {
            return (
              <input
                type="text"
                key={index}
                ref={(el) => (codesRef.current[index] = el)}
                maxLength={1}
                onPaste={(e) => handleOnPaste(e, index)}
                onKeyDown={(e) => debouncedCodeChange(e, index)}
                className="w-12 h-12 border-2 border-slate-300 rounded-md p-2 text-center focus:ring-1 focus:outline-none"
              />
            );
          })}
        </div>
        <ErrorMessage message={error} />
        <button
          disabled={isLoading || !isValid}
          className={`btn-solid justify-center mt-4 ${
            isLoading ? "loading" : ""
          }`}
          onClick={onConfirmValidate}
        >
          {isLoading ? "Validating" : "Confirm"}
        </button>
        <button
          className={`link mt-6 ${
            isResending ? "loading coloured inline-flex mx-auto" : ""
          }`}
          type="button"
          disabled={isResending}
          onClick={onResendClick}
        >
          {isResending ? "Resending..." : "Resend code"}
        </button>
      </div>
    </AuthLayout>
  );
}
