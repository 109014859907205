import React, { useState } from "react";
import Modal from "../../../components/Modal";
import toast from "react-hot-toast";
import { getErrorMessage } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import { toggleUseFit } from "../../../services/products";

export default function EnableFitConf({
  isOpen,
  onClose,
  onSuccess,
  isEdited,
  isFitEnabled,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onConfirm = async () => {
    setLoading(true);

    await toggleUseFit()
      .then((res) => {
        if (res) {
          if (isFitEnabled) {
            toast.success("Fit Recommendations disabled");
          } else {
            toast.success("Fit Recommendations enabled");
          }

          onClose();
          onSuccess();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={
        !isFitEnabled
          ? "Enable Fit Recommendation"
          : "Disable Fit Recommendation"
      }
      className="max-w-[560px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        {!isFitEnabled && !isEdited ? (
          <p>
            Turning on fit recommendation will use the default charts provided.
            These may not accurately reflect your feed. Please confirm to enable
            anyways.
          </p>
        ) : !isFitEnabled ? (
          <p>
            Turning on fit recommendation will show customers a message with
            info regarding how well the clothing will fit them when they try on
            a product.
          </p>
        ) : (
          <>
            <p>Are you sure you want to disable fit recommendations?</p>
            <p>
              Customers will no longer see a message for the fit recommendation
              when they try on a product.
            </p>
            <p>This can be re-enabled at any time.</p>
          </>
        )}
        <ErrorMessage message={error} />
        <div className="self-end items-center mt-6">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Close
          </button>
          <button
            disabled={isLoading}
            className={`btn-solid ${isFitEnabled ? "btn-red" : ""} ${
              isLoading ? "loading" : ""
            }`}
            onClick={onConfirm}
          >
            {isLoading
              ? "Loading..."
              : !isFitEnabled
              ? "Enable Recommendation"
              : "Disable Recommendation"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
