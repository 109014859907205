import React, { useState } from "react";
import Modal from "../../../components/Modal";
import toast from "react-hot-toast";
import { ADMIN_STATUS } from "../../../types/types";
import { getErrorMessage } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import { deleteTeamMember } from "../../../services/team";

export default function RemoveAdminModal({
  isOpen,
  selectedAdmin,
  onClose,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onDelete = async () => {
    setLoading(true);

    await deleteTeamMember({ id: selectedAdmin.id })
      .then((res) => {
        if (res) {
          toast.success("Removed Admin");
          onClose();
          onSuccess();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={
        selectedAdmin.status === ADMIN_STATUS.PENDING
          ? "Cancel Invitation"
          : "Remove Admin"
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        {selectedAdmin.status === ADMIN_STATUS.PENDING ? (
          <p>
            Are you sure you want to cancel the invitation to{" "}
            <strong>
              {selectedAdmin?.firstName} {selectedAdmin?.lastName}
            </strong>
            ? Their invite code will be made invalid.
          </p>
        ) : (
          <p>
            Are you sure you want to remove{" "}
            <strong>
              {selectedAdmin?.firstName} {selectedAdmin?.lastName}
            </strong>{" "}
            from the team? They will no longer have access to the Admin Portal.
          </p>
        )}
        <ErrorMessage message={error} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Close
          </button>
          <button
            disabled={isLoading}
            className={`btn-solid btn-red ${isLoading ? "loading" : ""}`}
            onClick={onDelete}
          >
            {isLoading
              ? "Loading..."
              : selectedAdmin.status === ADMIN_STATUS.PENDING
              ? "Cancel Invitation"
              : "Remove Admin"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
