import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const uploadProducts = (data, signal) => {
  return service({
    url: `${baseUrl}/${apiRoutes.UPLOAD_PRODUCTS}`,
    data,
    method: "post",
    signal,
  });
};

export const getProductUploadInstances = (params) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS_INSTANCES}`,
    params,
    method: "get",
  });
};

export const getProducts = (params) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS}`,
    params,
    method: "get",
  });
};

export const createProduct = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS}`,
    data,
    method: "post",
  });
};

export const updateProduct = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS}`,
    data,
    method: "patch",
  });
};

export const getProductById = ({ id }) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS}/${id}`,
    method: "get",
  });
};

export const deleteProduct = ({ id }) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PRODUCTS}/${id}`,
    method: "delete",
  });
};

export const getDefaultSizingCharts = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.DEFAULT_CHART}`,
    method: "get",
  });
};

export const updateSizingChart = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.UPDATE_SIZING_CHART}`,
    data,
    method: "patch",
  });
};

export const toggleUseFit = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.TOGGLE_FIT}`,
    method: "get",
  });
};
