import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import InputControl, { InputLabel } from "../../components/InputControl";
import Select, { components } from "react-select";
import ChevronDownIcon from "../../assets/svg/icon_chevron-down.svg?react";
import { capitalize, getErrorMessage } from "../../helpers/functions";
import toast from "react-hot-toast";
import ErrorMessage from "../../components/ErrorMessage";
import { createFeedback, getFeedbackCategories } from "../../services/feedback";

export default function SendFeedbackPage() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    await getFeedbackCategories()
      .then((res) => {
        if (res) {
          let options = [];

          if (res?.data?.length > 0) {
            let formattedCats = res.data.map((cat) => {
              return { value: cat.id, label: capitalize(cat.name) };
            });
            options = [...options, ...formattedCats];
          }
          setCategories(options);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DropdownIndicator = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4 ml-2 text-[var(--clr-text)]" />
      </components.DropdownIndicator>
    );
  };

  const onSendFeedback = async () => {
    setLoading(true);
    setError("");

    await createFeedback({
      title,
      message: description,
      categoryId: category.value,
    })
      .then((res) => {
        if (res) {
          toast.success("Feedback sent!");
          setTitle("");
          setCategory(undefined);
          setDescription("");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-10">
        <h1 className="page-title mb-0">Send Feedback</h1>
        <button
          type="button"
          disabled={
            isLoading || !title || !description || category === undefined
          }
          className={`btn-solid ${isLoading ? "loading" : ""}`}
          onClick={onSendFeedback}
        >
          Send
        </button>
      </div>
      <Card>
        <form className="flex flex-col gap-4">
          <InputControl
            label="Title"
            name="feedback-title"
            isRequired
            placeholder="Subject title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
          <div className="flex flex-col">
            <InputLabel label="Category" id="feedback-category" isRequired />
            <Select
              id="feedback-category"
              classNamePrefix="react-select"
              className="custom-select"
              placeholder="Select category"
              isSearchable={false}
              options={categories}
              value={category || ""}
              onChange={(res) => setCategory(res)}
              components={{ DropdownIndicator }}
            />
          </div>
          <InputControl
            label="Description"
            name="feedback-description"
            isRequired
            isTextArea
            isResizable
            placeholder="Description of feedback"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </form>
        <ErrorMessage message={error} className="mt-4" />
      </Card>
    </div>
  );
}
