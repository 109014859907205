import React from "react";
import AuthLayout from "../layout/AuthLayout";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-6">404</h1>
        <p className="text-xl mb-4">
          Oops! The page you were looking for does not exist
        </p>
        <button className="btn-solid" onClick={() => navigate("/")}>
          Return to Home
        </button>
      </div>
    </AuthLayout>
  );
}
