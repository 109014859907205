import React from "react";
import QuoteIcon from "../assets/svg/icon_arrow-left.svg?react";
import { useLocation, useNavigate } from "react-router-dom";

export default function BackButton({ className, defaultPath }) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <button
      title="Go back"
      className={`w-6 h-6 text-primary ${className}`}
      onClick={() => {
        if (location.key !== "default") {
          navigate(-1);
        } else {
          navigate(defaultPath);
        }
      }}
      type="button"
    >
      <QuoteIcon />
    </button>
  );
}
