import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import { useAvStore } from "../../store";
import {
  getAnalytics,
  getTryOnAnalytics,
  getTopProducts,
} from "../../services/analytics";
import {
  capitalize,
  formatNumber,
  getErrorMessage,
} from "../../helpers/functions";
import ErrorMessage from "../../components/ErrorMessage";
import Select, { components } from "react-select";
import ChevronDownIcon from "../../assets/svg/icon_chevron-down.svg?react";
import CalendarIcon from "../../assets/svg/icon_calendar.svg?react";
import { DATES, dateOptions } from "../../types/optionLists";
import { useSearchParams } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/Loader";

const DATE_PARAM = "date";
export default function Dashboard() {
  const user = useAvStore((state) => state.user);
  const [analyticsData, setAnalyticsData] = useState();
  const [analyticsError, setAnalyticsError] = useState("");
  const [isAnalyticsLoading, setAnalyticsLoading] = useState(true);
  const [tryonsData, setTryonsData] = useState();
  const [tryonsDataError, setTryonsDataError] = useState("");
  const [isTryonsDataLoading, setTryonsDataLoading] = useState(true);
  const dateOptionsList = getDateOptionsList();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const dateParam = searchParams.get(DATE_PARAM);
  const [topProductsList, setTopProductsList] = useState([]);
  const [isTopProductsLoading, setTopProductsLoading] = useState();
  const [topProductsError, setTopProductsError] = useState("");

  useEffect(() => {
    getAnalyticsDetails();
    getTopProductsList();
  }, []);

  useEffect(() => {
    getTryonsAnalyticsByDate(dateParam);
  }, [dateParam, startDate, endDate]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function getDateOptionsList() {
    return dateOptions.map((option) => {
      let label = option;
      if (label === DATES.THISWEEK) label = "This Week";
      if (label === DATES.THISMONTH) label = "This Month";
      return { value: option, label: capitalize(label) };
    });
  }
  const getAnalyticsDetails = async () => {
    setAnalyticsLoading(true);
    setAnalyticsError("");
    await getAnalytics()
      .then((res) => {
        if (res?.data) {
          setAnalyticsData(res.data);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setAnalyticsError(message);
      })
      .finally(() => {
        setAnalyticsLoading(false);
      });
  };

  const getTryonsAnalyticsByDate = async (params) => {
    const paramValue = dateOptionsList.find(
      (option) => option.label === params
    )?.value;
    setTryonsDataLoading(true);
    setTryonsDataError("");
    const body = {
      dateSearchBy: paramValue || dateOptions[0],
    };
    if (paramValue === DATES.CUSTOM) {
      body.startDate = startDate;
      body.endDate = endDate;
    }

    await getTryOnAnalytics(body)
      .then((res) => {
        if (res?.data !== null) {
          setTryonsData(res.data);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setTryonsDataError(message);
      })
      .finally(() => {
        setTryonsDataLoading(false);
      });
  };

  const getTopProductsList = async () => {
    setTopProductsLoading(true);
    setTopProductsError("");
    await getTopProducts()
      .then((res) => {
        if (res?.data) {
          setTopProductsList(res.data);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setTopProductsError(message);
      })
      .finally(() => {
        setTopProductsLoading(false);
      });
  };

  const DropdownIndicator = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4 ml-2 text-[var(--clr-text)]" />
      </components.DropdownIndicator>
    );
  };

  const onDateChange = (value) => {
    searchParams.set(DATE_PARAM, value);
    setSearchParams(searchParams);
  };

  return (
    <div className="container">
      <h1 className="page-title h-10">
        {user && `Welcome, ${user.firstName}!`}
      </h1>
      <div className="flex flex-col gap-4">
        <h2 className="page-subtitle">Overview</h2>
        {!isAnalyticsLoading && analyticsError && (
          <ErrorMessage message={analyticsError} />
        )}
        {!isAnalyticsLoading && !analyticsError && (
          <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-6">
            <Card className="text-center w-full">
              <p className="text-4xl font-semibold">
                {formatNumber(analyticsData?.totalCustomerTryOns)}
              </p>
              <p>total Try Ons</p>
            </Card>
            <Card className="text-center w-full">
              <p className="text-4xl font-semibold">
                {formatNumber(analyticsData?.activeTotalProducts)}
              </p>
              <p>Active Products</p>
            </Card>
            <Card className="text-center w-full">
              <p className="text-4xl font-semibold text-orange-300">
                {formatNumber(analyticsData?.inActiveTotalProducts)}
              </p>
              <p>Inactive Products</p>
            </Card>
          </div>
        )}
      </div>
      <Card className="mt-10">
        <div className="flex flex-col gap-4">
          <h2 className="page-subtitle">Try Ons Over Time</h2>
          <div className="flex flex-col md:flex-row gap-10">
            {!tryonsDataError && (
              <Card className="text-center max-w-[160px] w-full bg-primary-xlight self-start">
                <p className="h-[40px]">
                  <span className="text-4xl font-semibold">
                    {formatNumber(tryonsData)}
                  </span>
                </p>
                <p>try Ons</p>
              </Card>
            )}
            <div>
              <h3>Time Period</h3>
              <div className="flex flex-col gap-4 w-full">
                <Select
                  id="tryons-period"
                  classNamePrefix="react-select"
                  className="custom-select max-w-[180px]"
                  placeholder="Select category"
                  isSearchable={false}
                  options={dateOptionsList}
                  value={
                    dateOptionsList.find(
                      (option) => option.label === searchParams.get(DATE_PARAM)
                    ) || dateOptionsList[0]
                  }
                  onChange={(data) => onDateChange(data.label)}
                  components={{ DropdownIndicator }}
                />
                {
                  <div
                    className={`relative ${
                      searchParams.get(DATE_PARAM)?.toLowerCase() ===
                      DATES.CUSTOM.toLowerCase()
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <CalendarIcon className="w-5 h-5 absolute top-1/2 -translate-y-1/2 left-3 z-[2]" />

                    <ReactDatePicker
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      className="pl-12 input-style"
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Card>
      {topProductsList.length > 0 && (
        <Card className="mt-10 min-h-[240px]">
          <div className="flex flex-col gap-4">
            <div className="relative flex flex-col gap-4">
              <h2 className="page-subtitle">Top Products</h2>
            </div>
            {!isTopProductsLoading && topProductsError && (
              <ErrorMessage message={topProductsError} />
            )}
            {!topProductsError && isTopProductsLoading && <Loader />}
            {!isTopProductsLoading && !topProductsError && (
              <div className="grid gap-4 lg:grid-cols-2">
                {topProductsList.map((product, index) => {
                  return (
                    <div
                      key={index}
                      className="p-4 flex items-center justify-between border rounded-av gap-4 max-w-[420px]"
                    >
                      <div className="flex items-center">
                        <div className="w-[50px] h-[50px] flex-shrink-0 border rounded-md overflow-hidden mr-4">
                          <img
                            src={product.imageUrl}
                            alt="product"
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div className="flex flex-col text-sm">
                          <p className="line-clamp-2">{product.title}</p>
                        </div>
                      </div>
                      <div className="flex flex-col items-center">
                        <p className="text-xs text-av-gray-md text-center">
                          # Tryons
                        </p>
                        <p className="font-medium">{product.tryOns}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  );
}
