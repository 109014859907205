import React from "react";

export default function GuideSection({ title, children, className }) {
  return (
    <div className={`flex flex-col gap-4 ${className || ""}`}>
      <h2 className="page-subtitle pt-4">{title}</h2>
      {children}
    </div>
  );
}
