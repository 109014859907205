import React, { useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { getErrorMessage, getMimeType } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import toast from "react-hot-toast";
import { uploadProducts } from "../../../services/products";
import DocumentIcon from "../../../assets/svg/icon_document-bar.svg?react";
import CloseIcon from "../../../assets/svg/icon_close.svg?react";
import useAbortController from "../../../helpers/hooks/useAbortController";

export default function UploadDataModal({
  isOpen,
  onClose,
  onSuccess,
  acceptedTypes,
  maxFiles,
  maxFileSize,
}) {
  const [isLoading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [error, setError] = useState("");
  const fileInputRef = useRef();
  const getUploadSignal = useAbortController("upload-product-data");

  const formattedTypes = acceptedTypes.map((f) => getMimeType(f));

  const onUploadProductData = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");

    const body = new FormData();
    body.append("ProductFile", fileList[0]);

    await uploadProducts(body, getUploadSignal())
      .then((res) => {
        toast.success(res.data);
        onClose();
        onSuccess();
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadFile = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      let tooBig = false;
      for (let i = 0; i < files.length; i++) {
        let fileSize = (files[i].size / 1024 / 1024).toFixed(4); // MB formula
        if (fileSize > maxFileSize) {
          tooBig = true;
        }
      }
      if (
        fileList.length >= maxFiles ||
        files.length > maxFiles ||
        files.length + fileList.length > maxFiles
      ) {
        toast.error(`You cannot upload more than ${maxFiles} files.`);
      } else if (tooBig) {
        toast.error(`Files must be ${maxFileSize}MB or less in size`);
      } else {
        let newFiles = [...fileList, ...files];

        setFileList(newFiles);
      }
    } else {
      toast.error("File upload failed.");
    }
    e.target.value = null;
  };

  return (
    <Modal title="Upload Product Data" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={onUploadProductData} className="flex flex-col gap-4">
        {fileList?.length === 0 ? (
          <div
            className={`flex flex-col justify-center text-center text-sm slate-gray-500 items-center cursor-pointer border-2 border-white-smoke-dark border-dashed rounded-av
             h-[200px] px-4`}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            <span className="bg-white-smoke p-2 rounded-2xl">
              <DocumentIcon className="w-6 h-6 text-primary" />
            </span>
            <div className="font-medium">
              <p>Drag and drop your file here</p>
              <p>
                or <span className="text-primary">Browse</span>
              </p>
            </div>
            <p className="text-xs mt-2 text-av-gray-md px-2">
              Allowed formats: {acceptedTypes.join(", ")}
            </p>
          </div>
        ) : (
          <div className="flex items-start bg-white-smoke p-4 rounded-av">
            <DocumentIcon className="w-6 h-6 text-primary mr-2" />
            {fileList[0].name}
            <button
              className="ml-auto"
              onClick={() => {
                setFileList([]);
                fileInputRef.value = null;
              }}
            >
              <CloseIcon className="hover:text-primary ml-2 w-5 h-5" />
            </button>
          </div>
        )}

        <input
          type="file"
          id="file-uploader"
          multiple={maxFiles > 1}
          className="hidden"
          ref={fileInputRef}
          accept={formattedTypes.join(",")}
          onChange={uploadFile}
        />

        <ErrorMessage message={error} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            disabled={isLoading || fileList.length === 0}
            className={`btn-solid ${isLoading ? "loading" : ""}`}
          >
            {isLoading ? "Loading..." : "Upload"}
          </button>
        </div>
      </form>
    </Modal>
  );
}

UploadDataModal.defaultProps = {
  maxFiles: 1,
  acceptedTypes: ["csv"],
  maxFileSize: 10,
};
