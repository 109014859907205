const DATES = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  THISWEEK: "thisweek",
  THISMONTH: "thismonth",
  CUSTOM: "custom",
};

const dateOptions = [
  DATES.TODAY,
  DATES.YESTERDAY,
  DATES.THISWEEK,
  DATES.THISMONTH,
  DATES.CUSTOM,
];
const GENDER_LABEL = { MALE: "Male", FEMALE: "Female" };
const GENDER_TYPE = { MALE: "m", FEMALE: "f" };
const genderOptions = [GENDER_TYPE.MALE, GENDER_TYPE.FEMALE];
const sizingCategoryOptions = ["top", "bottom", "fullbody", "dress"];
const sizingCategoryTypeOptions = ["short", "long"];

const LIST_TYPE = {
  GENDER: "gender",
  SIZING_CAT_TYPE: "sizing-cat-type",
  SIZING_CAT: "sizing-cat",
};
export {
  dateOptions,
  genderOptions,
  sizingCategoryOptions,
  sizingCategoryTypeOptions,
  DATES,
  LIST_TYPE,
  GENDER_TYPE,
  GENDER_LABEL,
};
