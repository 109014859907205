import React, { useState } from "react";
import Modal from "../../components/Modal";
import {
  DashboardImage,
  ProductDetailsImage,
  ProductsImage,
  TeamImage,
  ProductUploadImage,
  WidgetImage,
  TutorialLinkImage,
  FeedbackImage,
} from "../../assets/images/tutorial-images/tutorial";
import StepsComponent from "../../components/StepsComponent";
import { getProfile, markTutorialAsRead } from "../../services/vendor";
import { useAvStore } from "../../store";
import useAbortController from "../../helpers/hooks/useAbortController";

const slides = [
  {
    step: 0,
    title: "Dashboard",
    text: "All analytics can be seen in the dashboard. Use filters to narrow the information down for your preference.",
    image: DashboardImage,
  },
  {
    step: 1,
    title: "Adding Products to Your Feed",
    text: "Only eligible products that have been added to your Product Feed will display the 'Try on With Avease' button. Upload a CSV file of your existing products to get started.",
    image: ProductUploadImage,
  },
  {
    step: 2,
    title: "Integrating our Avease Widget",
    text: "Once your product feed is set up, add our widget to your website for your customers to interact with. Follow the steps in our integration guide to ensure everything is set up.",
    image: WidgetImage,
  },
  {
    step: 3,
    title: "Viewing the Product Feed",
    text: "Products that have been added can be seen and managed in the Product Feed. Any products with missing information will be marked. You can also add new products to the feed here (one at a time).",
    image: ProductsImage,
  },
  {
    step: 4,
    title: "Updating Product Details",
    text: "Click on a product to view it's details, and edit or delete the product.",
    image: ProductDetailsImage,
  },
  {
    step: 5,
    title: "Managing Your Team",
    text: "Giving Vendor Portal access to other members of your team is easy. Send an invite through the teams tab to invite another admin.",
    image: TeamImage,
  },

  {
    step: 6,
    title: "Sending Feedback",
    text: "In the event that you need help or have any questions, you can send feedback to our Avease Admins through the send feedback page.",
    image: FeedbackImage,
  },
];

export default function TutorialModal({ isOpen, onClose, hasViewedTutorial }) {
  const [currentSlide, setCurrentSlide] = useState(undefined);
  const [isCompleted, setCompleted] = useState(false);
  const getUpdateTutorialSignal = useAbortController("tutorial");

  const handleNextClick = () => {
    const nextIndex = currentSlide?.step + 1;
    if (nextIndex > slides.length - 1) {
      /* Completing last step of the tutorial. */
      completeTutorial();
      setCompleted(true);
    }
    try {
      const nextSlide = slides[nextIndex];
      setCurrentSlide(nextSlide);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePreviousClick = () => {
    const prevIndex = currentSlide?.step - 1;
    if (prevIndex < 0) return;

    try {
      const prevSlide = slides[prevIndex];
      setCurrentSlide(prevSlide);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
    setCompleted(false);
    setCurrentSlide(undefined);
    completeTutorial();
  };

  const completeTutorial = async () => {
    if (hasViewedTutorial) return;
    await markTutorialAsRead(getUpdateTutorialSignal())
      .then((res) => {
        if (res) {
          getVendorProfile();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getVendorProfile = async () => {
    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Modal
      title={!isCompleted ? "Tutorial" : ""}
      isOpen={isOpen}
      onClose={handleClose}
      className="max-w-[960px]"
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          {/* Start Screen */}

          {!currentSlide && !isCompleted && (
            <div className="flex flex-col gap-4 py-6 text-center">
              <p className="text-4xl font-semibold">
                Welcome to the Avease Vendor Portal!
              </p>
              <p>
                This is a short tutorial meant to get you started with Avease.
              </p>
              <p>
                If you'd like to view this tutorial at another time, it can be
                found in the account drop down in the top right of your portal.
              </p>
            </div>
          )}

          {/* Steps Screens */}
          {!isCompleted && currentSlide && (
            <div>
              <div className="max-h-[300px] overflow-hidden border rounded-lg">
                <img
                  src={currentSlide.image}
                  alt={currentSlide.title}
                  className="w-full object-cover"
                />
              </div>
              <div className="py-6">
                <p className="text-lg font-semibold">{currentSlide.title}</p>
                <p>{currentSlide.text}</p>
              </div>
            </div>
          )}

          {/* Completed Screen */}

          <div
            className={`${
              isCompleted ? "flex" : "hidden"
            } flex-col gap-4 py-6 text-center`}
          >
            <p className="text-4xl font-semibold">Tutorial Completed!</p>
            <p>
              If you'd like to view this tutorial again, it can be found in the
              account drop down in the top right of your portal.
            </p>
            <div className="max-h-[240px] overflow-hidden">
              <img
                src={TutorialLinkImage}
                alt="Tutorial link location"
                className="w-full object-cover"
              />
            </div>
          </div>
        </div>

        {!currentSlide && !isCompleted && (
          <div className="flex flex-col items-center justify-center">
            <button
              type="button"
              className="btn-solid"
              onClick={() => setCurrentSlide(slides[0])}
            >
              Get Started
            </button>
            <button
              className="text-sm hover:underline text-av-gray-md mt-2"
              onClick={handleClose}
            >
              I'll look at this another time
            </button>
          </div>
        )}
        {!isCompleted && currentSlide && (
          <div className="flex justify-between items-center">
            <StepsComponent currentStep={currentSlide?.step} steps={slides} />
            <div className="flex items-center">
              <button
                type="button"
                disabled={currentSlide?.step - 1 < 0}
                className="btn-outline mr-2"
                onClick={handlePreviousClick}
              >
                Previous
              </button>
              <button
                type="button"
                className={`btn-solid`}
                onClick={handleNextClick}
              >
                {currentSlide?.step + 1 > slides.length - 1
                  ? "Complete Tutorial"
                  : "Next"}
              </button>
            </div>
          </div>
        )}
        {isCompleted && (
          <button
            type="button"
            className="btn-outline mr-2 self-end"
            onClick={handleClose}
          >
            Close
          </button>
        )}
      </div>
    </Modal>
  );
}
