import React from "react";
import Modal from "../../../components/Modal";

export default function ProductErrorModal({ isOpen, data, onClose }) {
  const formatDetails = (data) => {
    if (data === null) return [];

    let list = data.split("\n");
    if (list.length > 0) {
      list = list.filter((i) => i !== "");
    } else {
      return [];
    }

    return list;
  };

  return (
    <Modal
      title="Product Missing Details Report"
      className="max-w-[520px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        <ol className="list-inside list-disc">
          {data &&
            formatDetails(data).map((message, index) => {
              return <li key={index}>{message}</li>;
            })}
        </ol>
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
