import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import ReactTable from "../../components/ReactTable";
import { getProductUploadInstances } from "../../services/products";
import { getErrorMessage } from "../../helpers/functions";
import { Link, useSearchParams } from "react-router-dom";
import UploadDataModal from "./modals/UploadDataModal";
import ErrorMessage from "../../components/ErrorMessage";
import { createColumnHelper } from "@tanstack/react-table";
import InputControl from "../../components/InputControl";
import SearchIcon from "../../assets/svg/icon_search.svg?react";
import UploadIcon from "../../assets/svg/icon_upload.svg?react";

const columnHelper = createColumnHelper();

export default function UploadProductDataPage() {
  const [instances, setInstances] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUploadModalShowing, setUploadModalShowing] = useState(false);
  const [searchValue, setSearchValue] = useState(
    searchParams.get("searchBy") || ""
  );

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "Name",
      cell: (info) => <p>{info.getValue().fileName}</p>,
      header: () => <span>Name</span>,
    }),

    columnHelper.accessor("uploadedOn", {
      cell: (info) => <p className="min-w-[100px]">{info.getValue()}</p>,
      header: () => <span>Uploaded On</span>,
    }),
    columnHelper.accessor("totalProductProcessed", {
      cell: (info) => info.getValue(),
      header: () => <span>Active Products Added</span>,
    }),
    columnHelper.accessor("totalProductCount", {
      cell: (info) => info.getValue(),
      header: () => <span>Total Products</span>,
    }),
    columnHelper.accessor("productProcessingStatus", {
      cell: (info) => info.getValue(),
      header: () => <span>Status</span>,
    }),
  ];

  useEffect(() => {
    getHistory();
  }, [searchParams]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (searchValue) {
        searchParams.set("searchBy", searchValue);
      } else {
        searchParams.delete("searchBy");
      }
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(t);
  }, [searchValue]);

  const getHistory = async () => {
    setLoading(true);
    setError("");

    await getProductUploadInstances({
      Page: searchParams.get("page") || 1,
      PerPage: 10,
      SearchBy: searchParams.get("searchBy") || "",
    })
      .then((res) => {
        if (res) {
          setInstances(res.data.items);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-10">
        <h1 className="page-title mb-0">Upload Product Data</h1>
        <button
          className="btn-solid"
          onClick={() => setUploadModalShowing(true)}
        >
          <UploadIcon className="btn-prefix-icon" />
          Upload
        </button>
        <UploadDataModal
          isOpen={isUploadModalShowing}
          onClose={() => setUploadModalShowing(false)}
          onSuccess={() => getHistory()}
        />
      </div>
      <div className="flex flex-col gap-4">
        <Card className="flex flex-col gap-4">
          <h2 className="page-subtitle">Instructions</h2>
          <p>
            Before uploading product data, please look at the{" "}
            <Link to="/product-feed-formatting" className="link">
              Feed Formatting page
            </Link>{" "}
            and ensure your csv format contains the required fields. This will
            ensure the smoothest data upload.
          </p>
          <p>
            Products with missing fields will be marked accordingly in the{" "}
            <Link to="/products" className="link">
              Product Feed
            </Link>{" "}
            and will be automatically excluded/unavailable to end users.
          </p>
        </Card>
        <Card>
          <h2 className="page-subtitle">Upload History</h2>

          {!isLoading && error ? (
            <ErrorMessage message={error} className="mt-4" />
          ) : (
            <Card>
              <InputControl
                type="search"
                name="search-bar"
                className="mb-4"
                clearable
                onClear={() => setSearchValue("")}
                placeholder="Search history..."
                prefixIcon={<SearchIcon className="prefix-icon" />}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />

              <ReactTable
                columns={columns}
                data={instances}
                isLoading={isLoading}
                totalPages={totalPages}
                emptyMessage="No upload history found..."
              />
            </Card>
          )}
        </Card>
      </div>
    </div>
  );
}
