import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import InputControl from "../../../components/InputControl";
import { getErrorMessage, parsePhoneNumber } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import { updateProfileInfo } from "../../../services/vendor";
import toast from "react-hot-toast";
import { useAvStore } from "../../../store";
import { useDebouncedCallback } from "use-debounce";

export default function EditContactInfoModal({ isOpen, onClose, user }) {
  const [isChangeLoading, setChangeLoading] = useState(false);
  const [phone, setPhone] = useState(user?.phoneNumber || "");
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [changeError, setChangeError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    if (user?.phoneNumber) {
      const res = validatePhone(user.phoneNumber);
      setPhoneError(res);
    }
  }, [user]);

  const onEditContactInfo = async (e) => {
    e.preventDefault();
    setChangeLoading(true);
    setChangeError("");

    const phoneNumber = parsePhoneNumber(phone);

    await updateProfileInfo({
      phoneNumber,
      firstName,
      lastName,
    })
      .then((res) => {
        if (res) {
          toast.success("Profile details updated");
          useAvStore
            .getState()
            .setUser({ ...user, firstName, lastName, phoneNumber });
          onClose();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setChangeError(message);
      })
      .finally(() => {
        setChangeLoading(false);
      });
  };

  const debouncedValidatePhone = useDebouncedCallback(
    // function
    (value) => {
      validatePhone(value);
    },
    // delay in ms
    100
  );

  const validatePhone = (value) => {
    let regex = /\d/g;
    setTimeout(() => {
      let result = value.match(regex);
      const valid = result && result.length === 11;
      if (valid || value === "") {
        setPhoneError("");
      } else {
        setPhoneError(
          "Invalid phone number. Phone number must contain 11 digits."
        );
      }
    }, 1000);
  };

  return (
    <Modal title="Edit Contact Info" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={onEditContactInfo} className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <InputControl
            label="First Name"
            name="first-name"
            placeholder="Your first name"
            isRequired
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <InputControl
            label="Last Name"
            name="last-name"
            placeholder="Your last name"
            isRequired
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <InputControl
            label="Phone Number"
            name="phone-number"
            placeholder="+1 403 123 4567"
            errorMessage={phoneError}
            isRequired
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
              debouncedValidatePhone(e.target.value);
            }}
          />
        </div>

        <ErrorMessage message={changeError} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              !firstName || !lastName || !phone || phoneError || isChangeLoading
            }
            className={`btn-solid ${isChangeLoading ? "loading" : ""}`}
          >
            {isChangeLoading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
