import React from "react";
import Logo from "../assets/svg/avease_logo_color.svg?react";
import { checkAuthentication } from "../store";
import { Navigate } from "react-router-dom";

export default function AuthLayout({ children }) {
  const isAuthenticated = checkAuthentication();

  if (isAuthenticated) return <Navigate to="/" replace />;

  return (
    <div className="relative grid lg:grid-cols-3 min-h-screen bg-primary lg:bg-white-smoke">
      <div className="hidden lg:block lg:col-span-2 from-av-purple via-primary to-av-orange bg-gradient-to-tr"></div>
      <div className="lg:absolute inset-0 grid lg:grid-cols-2 gap-4 self-center max-w-[1600px] lg:w-4/5 w-full mx-auto my-10 lg:my-0">
        <div className="flex flex-col items-center lg:items-start lg:justify-between lg:h-[500px] self-center">
          <Logo className="w-full max-w-[100px] lg:max-w-[150px] mb-10 text-white" />
          <p className="hidden lg:inline-block text-5xl font-extrabold text-white">
            Welcome to the Avease Vendor Portal!
          </p>
        </div>
        <div className="bg-white p-10 rounded-[20px] max-w-[440px] w-full mx-auto lg:mx-0 self-center">
          {children}
        </div>
      </div>
    </div>
  );
}
