import React from "react";
import GuideSection from "./GuideSection";

export default function ShopifyGuide({ className }) {
  return (
    <div className={`flex flex-col gap-6 ${className || ""}`}>
      <p>This guide is specific to the Shopify Dawn Theme.</p>
      <GuideSection title="1. Adding the script">
        <p>
          Add the custom script to your website's Assets file and add it to a
          Custom Liquid Block in your default product template.
        </p>
      </GuideSection>

      <GuideSection title="2. Setting up the embedded HTML snippet">
        <p>
          Add the embedded HTML snippet where you want the Try On With Avease to
          be located. Either the same Custom Liquid Block or a separate block.
        </p>
      </GuideSection>

      <GuideSection title="3. Setup Complete">
        <p>
          Ensure setup is working as expected by clicking the Try On with Avease
          Button and observing the signup iframe.
        </p>
      </GuideSection>
    </div>
  );
}
