import { TABLE_MEASUREMENTS } from "../types/types";

export const getImageUrl = (name) => {
  return new URL(`../assets/images/${name}.jpg`, import.meta.url).href;
};

export function capitalize(string) {
  if (!string) return string;
  if (string.length === 1) return string.toUpperCase();
  return string[0].toUpperCase() + string.substring(1);
}
export const parsePhoneNumber = (value) => {
  let newVal = value.trim();

  //Remove all non-digits
  newVal = newVal.replace(/\D/g, "");
  return `+${newVal}`;
};

export function getErrorMessage(error, customizedDefault) {
  const defaultMessage =
    customizedDefault ||
    "Sorry, an unexpected error has occurred. Please try again or contact our team if the issue still persists.";
  let e = defaultMessage;
  if (error?.response?.data) {
    e =
      error.response.data.errorDetails ||
      error.response.data.title ||
      error.response.data.message ||
      error.response.data.errorMessage ||
      error.response.data;
  }
  if (error?.code === "ERR_NETWORK") {
    return "Sorry, we are unable to complete the action due to a network issue. Please check your connection or try again later.";
  }

  return e;
}

export function getMimeType(format) {
  const images = ["png", "jpeg", "webp"];
  const text = ["csv"];

  if (images.includes(format)) {
    return `image/${format}`;
  }
  if (text.includes(format)) {
    return `text/${format}`;
  }
  return "";
}

export function convertCamelToTitle(text) {
  if (!text) return "";
  let baseString = text
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    });
  return baseString;
}

/* Chart  helper functions */
export function getChartLabel(val) {
  if (!val) return;
  let baseString = val.split("SizingChart")[0];
  baseString = convertCamelToTitle(baseString);

  return baseString;
}
export function getMeasurementsList(sizes, keepNulls) {
  if (!sizes || sizes.length < 1) return [];
  const sampleItem = sizes[0];
  if (keepNulls)
    return Object.keys(sampleItem).filter((m) => m !== "name" && m !== "id");
  return Object.keys(sampleItem).filter(
    (m) => sampleItem[m] !== null && m !== "name" && m !== "id"
  );
}
export function formatChartData(data) {
  if (!data || data.sizes?.length < 1) return [];
  const sizes = data.sizes;
  const measurements = getMeasurementsList(sizes);

  let newData = {};
  measurements.forEach((measurement) => {
    newData[measurement] = [];
  });
  let finalData = [];
  sizes.forEach((size) => {
    measurements.forEach((measurement) => {
      let measurementStats = size[measurement];

      if (measurementStats !== null) {
        let newBody = {
          size: size?.name,
          min: measurementStats?.min,
          max: measurementStats?.max,
        };
        if (size?.id) {
          newBody.id = size.id;
        }
        newData[measurement].push(newBody);
      }
    });
  });

  finalData = Object.keys(newData).map((row) => {
    return { title: row, measurements: newData[row] };
  });

  return finalData;
}

export function formatClothingMeasurement({
  val,
  current,
  addUnits,
  originalMeasurement,
}) {
  if (!val) return "";
  let conversion = 0.393701; // 1 cm = this many inches
  if (current === TABLE_MEASUREMENTS.CM) {
    let newVal = val.toFixed(1);
    if (originalMeasurement === TABLE_MEASUREMENTS.INCHES)
      newVal = (val / conversion).toFixed(1);
    if (!addUnits) return newVal;

    return newVal + "cm";
  } else {
    let newVal = val.toFixed(1);
    if (originalMeasurement === TABLE_MEASUREMENTS.CM)
      newVal = (val * conversion).toFixed(1);
    if (!addUnits) return newVal;
    return newVal + '"';
  }
}

export function formatNumber(num) {
  if (num === null || num === undefined) return "";
  return num.toLocaleString("en-US");
}
