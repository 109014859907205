import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import InputControl, { InputLabel } from "../../../components/InputControl";
import ImageUploader, {
  UploadPreview,
} from "../../../components/ImageUploader";
import useAbortController from "../../../helpers/hooks/useAbortController";
import { sendUpdateProfileRequest } from "../../../services/vendor";
import { getErrorMessage } from "../../../helpers/functions";
import toast from "react-hot-toast";
import ErrorMessage from "../../../components/ErrorMessage";
import { getCountries } from "../../../services/settings";
import Select, { components } from "react-select";
import ChevronDownIcon from "../../../assets/svg/icon_chevron-down.svg?react";

export default function EditProfileModal({ isOpen, onClose, profileDetails }) {
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [country, setCountry] = useState(undefined);
  const [estimatedRevenue, setEstimatedRevenue] = useState("");
  const [website, setWebsite] = useState("");

  const [fileList, setFileList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const getEditProfileSignal = useAbortController("edit-profile");
  const [revenueError, setRevenueError] = useState("");
  const [validRevenue, setValidRevenue] = useState(false);
  const [countries, setCountries] = useState([]);
  const [currentImageUrl, setCurrentImageUrl] = useState("");

  useEffect(() => {
    setError("");
    resetFields();
    if (profileDetails) {
      setBusinessName(profileDetails.businessName);
      setBusinessAddress(profileDetails.address || "");
      if (profileDetails.estimatedRevenue) {
        validateRevenue(profileDetails.estimatedRevenue.toString());
      }
      setEstimatedRevenue(profileDetails.estimatedRevenue);
      setWebsite(profileDetails.website || "");
      setCountry(
        profileDetails.country
          ? { value: profileDetails.country, label: profileDetails.country }
          : undefined
      );
      setCurrentImageUrl(profileDetails.profileLogoUrl || "");
    }
    if (isOpen && countries.length === 0) {
      fetchCountries();
    }
  }, [isOpen]);

  const fetchCountries = async () => {
    await getCountries()
      .then((res) => {
        if (res) {
          const countryOptions = res.data.map((country) => {
            return { value: country, label: country };
          });
          setCountries(countryOptions);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onEditProfile = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    let profileData = new FormData();
    const profileBody = {
      businessName,
      website,
      estimatedRevenue,
      country: country.value,
      address: businessAddress,
      profileLogoId: profileDetails.profileLogoId,
      logo: fileList[0],
    };

    for (let key in profileBody) {
      profileData.append(key, profileBody[key]);
    }

    await sendUpdateProfileRequest(profileData, getEditProfileSignal())
      .then((res) => {
        if (res) {
          toast.success("Request for changes sent.");
          onClose();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetFields = () => {
    setBusinessName("");
    setWebsite("");
    setEstimatedRevenue("");
    setCountry(undefined);
    setBusinessAddress("");
    setFileList([]);
  };

  const validateRevenue = (value) => {
    setTimeout(() => {
      setRevenueError("");
      const revenue = parseInt(
        value
          .split("")
          .filter((s) => s !== ",")
          .join("")
      );
      if (isNaN(revenue)) {
        setValidRevenue(false);
        setRevenueError("Revenue should be a number");
      } else {
        setValidRevenue(true);
      }
    }, 700);
  };

  const DropdownIndicator = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4 ml-2 text-[var(--clr-text)]" />
      </components.DropdownIndicator>
    );
  };

  return (
    <Modal
      title="Edit Profile"
      description="Changes to profile details will be sent to the admin for approval."
      className="max-w-[720px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onEditProfile} className="flex flex-col gap-4">
        <div className="grid md:grid-cols-2">
          <div>
            <div className="flex flex-col items-center">
              <div className="flex">
                <p className="mb-1 mr-auto">Company Logo</p>
                <span className="text-red-500 ml-1">*</span>
              </div>
              {currentImageUrl ? (
                <UploadPreview
                  preview={currentImageUrl}
                  isRounded
                  onClose={() => setCurrentImageUrl("")}
                />
              ) : (
                <ImageUploader
                  fileList={fileList}
                  isRounded
                  className="mx-auto"
                  onChange={(files) => setFileList(files)}
                  maxFiles={1}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <InputControl
              label="Business Name"
              name="business-name"
              placeholder="Enter business name"
              isRequired
              value={businessName}
              onChange={(e) => {
                setBusinessName(e.target.value);
              }}
            />
            <InputControl
              label="Business Address"
              name="address"
              placeholder="Enter address"
              isRequired
              value={businessAddress}
              onChange={(e) => {
                setBusinessAddress(e.target.value);
              }}
            />
            <div className="flex flex-col">
              <InputLabel label="Country" id="country" isRequired />
              <Select
                id="country"
                classNamePrefix="react-select"
                className="custom-select"
                placeholder="Select country"
                options={countries}
                value={country || ""}
                onChange={(res) => setCountry(res)}
                components={{ DropdownIndicator }}
              />
            </div>
            <InputControl
              label="Website"
              name="website"
              placeholder="www.your-business.com"
              isRequired
              value={website}
              onChange={(e) => {
                setWebsite(e.target.value);
              }}
            />
            <InputControl
              label="Estimated Revenue"
              name="vendor-revenue"
              placeholder="100,000"
              errorMessage={revenueError}
              isRequired
              value={estimatedRevenue}
              onChange={(e) => {
                setEstimatedRevenue(e.target.value);
                validateRevenue(e.target.value);
              }}
            />
          </div>
        </div>
        <ErrorMessage message={error} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              !validRevenue ||
              revenueError ||
              !estimatedRevenue ||
              !country ||
              !website ||
              !businessName ||
              !businessAddress ||
              (fileList.length === 0 && !currentImageUrl) ||
              isLoading
            }
            className={`btn-solid ${isLoading ? "loading" : ""}`}
          >
            {isLoading ? "Loading..." : "Request Changes"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
