import React from "react";
import { useParams } from "react-router-dom";
import { capitalize } from "../../../helpers/functions";
import Card from "../../../components/Card";
import ShopifyGuide from "./ShopifyGuide";
import BackButton from "../../../components/BackButton";
import NeedHelpSection from "./NeedHelpSection";

export default function IntegrationGuide() {
  const { id } = useParams();

  return (
    <div className="container">
      <div className="flex items-start">
        <BackButton className="mr-2" defaultPath="/sizing-charts" />
        <h1 className="page-title">
          {id ? `${capitalize(id)} ` : ""}Integration Guide
        </h1>
      </div>
      <div className="flex flex-col gap-4 max-w-[820px] w-full">
        <Card>{id === "shopify" && <ShopifyGuide />}</Card>
        <NeedHelpSection />
      </div>
    </div>
  );
}
