import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import InputControl from "../../components/InputControl";
import { passwordValidationRules } from "../../types/regex";
import PasswordRule from "../../components/PasswordRule";
import ErrorMessage from "../../components/ErrorMessage";
import CustomSwitch from "../../components/CustomSwitch";
import { changePassword, toggleMfa } from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import toast from "react-hot-toast";
import { useAvStore } from "../../store";

export default function AdminSecuritySettings() {
  const user = useAvStore((state) => state.user);
  const [isValid, setValid] = useState(false);
  const [isChangePasswordLoading, setChangePasswordLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordError, setChangePasswordError] = useState("");
  const [isToggling, setToggling] = useState(false);
  const [require2FA, setRequire2FA] = useState(user?.require2FA || false);
  const [toggleMfaError, setToggleMfaError] = useState("");

  useEffect(() => {
    if (user) {
      setRequire2FA(user.require2FA);
    }
  }, [user]);

  const validatePassword = (value) => {
    if (value) {
      let results = Object.keys(passwordValidationRules).map((r) => {
        return {
          rule: r,
          valid: passwordValidationRules[r].test(value.toString()),
        };
      });
      setValid(results.every((v) => v.valid));
    }
  };

  const validateRule = (rule) => {
    if (rule === "match") return newPassword === confirmPassword;
    if (rule && passwordValidationRules[rule]) {
      return passwordValidationRules[rule].test(newPassword);
    }
    return false;
  };

  const onChangePassword = async (e) => {
    e.preventDefault();

    setChangePasswordLoading(true);
    setChangePasswordError("");

    await changePassword({
      password: password.trim(),
      newPassword: newPassword.trim(),
      passwordConfirmation: confirmPassword.trim(),
    })
      .then((res) => {
        if (res) {
          toast.success("Password updated");
          setPassword("");
          setNewPassword("");
          setConfirmPassword("");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setChangePasswordError(message);
      })
      .finally(() => {
        setChangePasswordLoading(false);
      });
  };

  const onToggleAuth = async () => {
    setToggling(true);
    await toggleMfa()
      .then((res) => {
        if (res) {
          if (user.require2FA) {
            toast.success("2FA toggled OFF");
          } else {
            toast.success("2FA toggled ON");
          }
          useAvStore
            .getState()
            .setUser({ ...user, require2FA: !user.require2FA });
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setToggleMfaError(message);
      })
      .finally(() => setToggling(false));
  };

  return (
    <div className="container">
      <h1 className="page-title">Security Settings</h1>
      <div className="grid md:grid-cols-2 gap-10">
        <Card>
          <form className="flex flex-col gap-4" onSubmit={onChangePassword}>
            <div className="flex items-center justify-between">
              <h2 className="page-subtitle">Change Password</h2>
              <button
                disabled={
                  isChangePasswordLoading ||
                  !isValid ||
                  !password ||
                  !confirmPassword ||
                  !newPassword ||
                  newPassword !== confirmPassword
                }
                className={`btn-solid btn-mini ${
                  isChangePasswordLoading ? "loading" : ""
                }`}
                type="submit"
              >
                {isChangePasswordLoading ? "Loading..." : "Save"}
              </button>
            </div>
            <p>Update your password.</p>
            <ErrorMessage message={changePasswordError} />
            <InputControl
              label="Current Password"
              name="current-password"
              type="password"
              autoComplete="current-password"
              placeholder="Current password"
              isRequired
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <InputControl
              label="Password"
              name="password"
              type="password"
              autoComplete="new-password"
              placeholder="New password"
              isRequired
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                validatePassword(e.target.value);
              }}
            />
            <InputControl
              label="Confirm Password"
              name="confirm-password"
              type="password"
              placeholder="Confirm password"
              isRequired
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <div>
              <PasswordRule
                isValid={validateRule("match")}
                text="Passwords match"
              />
              <PasswordRule
                isValid={validateRule("lowercase")}
                text="1 lowercase character"
              />
              <PasswordRule
                isValid={validateRule("uppercase")}
                text="1 uppercase character"
              />
              <PasswordRule
                isValid={validateRule("special")}
                text="1 special character"
              />
              <PasswordRule
                isValid={validateRule("length")}
                text="8 characters minimum"
              />
            </div>
          </form>
        </Card>

        <Card className="flex flex-col gap-4 mb-auto">
          <div className="flex items-center justify-between">
            <h2 className="page-subtitle">Two Factor Authentication</h2>
            <button
              className={`btn-solid btn-mini ${isToggling ? "loading" : ""}`}
              onClick={onToggleAuth}
              disabled={user?.require2FA === require2FA || isToggling}
            >
              Save
            </button>
          </div>
          <ErrorMessage message={toggleMfaError} />
          <CustomSwitch
            name="2fa-switch"
            label="Require 2FA for Vendor login"
            value={require2FA}
            onChange={(val) => setRequire2FA(val)}
          />
          <p className="text-sm text-lighter">
            With 2FA enabled, you will be sent a verification email when logging
            in to the portal to confirm your identity.
          </p>
        </Card>
      </div>
    </div>
  );
}
