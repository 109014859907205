import React from "react";
import * as Switch from "@radix-ui/react-switch";

const CustomSwitch = ({ name, label, value, onChange, title }) => (
  <form>
    <div style={{ display: "flex", alignItems: "center" }} title={title}>
      <label className="mb-1" htmlFor={name} style={{ paddingRight: 15 }}>
        {label}
      </label>
      <Switch.Root
        className="relative w-[42px] h-[25px] rounded-full shadow-sm bg-slate-100 [&[data-state='checked']]:bg-primary"
        id={name}
        checked={value}
        onCheckedChange={onChange}
      >
        <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-sm transition-transform duration-100 translate-x-[2px] [&[data-state='checked']]:translate-x-[19px]" />
      </Switch.Root>
    </div>
  </form>
);

export default CustomSwitch;
