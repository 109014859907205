import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const getFeedbackCategories = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.FEEDBACK_CATEGORIES}`,
    method: "get",
  });
};

export const createFeedback = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.FEEDBACK}`,
    data,
    method: "post",
  });
};
