import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import { REGEX_VALIDATION } from "../../../types/regex";
import InputControl from "../../../components/InputControl";
import toast from "react-hot-toast";
import { inviteAdmin } from "../../../services/authentication";
import { getErrorMessage, parsePhoneNumber } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import useAbortController from "../../../helpers/hooks/useAbortController";
import { useDebouncedCallback } from "use-debounce";

export default function InviteAdminModal({ isOpen, onClose, onSuccess }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [inviteError, setInviteError] = useState();
  const getInviteAdminSignal = useAbortController("invite-admin");

  useEffect(() => {
    setPhoneError("");
    setEmailError("");
    setInviteError("");

    resetFields();
  }, [isOpen]);

  const onInviteAdmin = async (e) => {
    e.preventDefault();

    setLoading(true);
    setInviteError("");

    const phoneNumber = parsePhoneNumber(phone);

    await inviteAdmin(
      {
        email,
        firstName,
        lastName,
        phoneNumber,
        require2FA: false,
      },
      getInviteAdminSignal()
    )
      .then((res) => {
        if (res) {
          resetFields();
          toast.success("Invite Sent");
          onSuccess();
          onClose();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setInviteError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetFields = () => {
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
  };

  const validateEmail = (value) => {
    let regex = REGEX_VALIDATION.EMAIL;

    setTimeout(() => {
      setEmailError("");
      let result = regex.test(value);
      if (result || value === "") {
        setValidEmail(true);
      } else {
        setValidEmail(false);
        setEmailError("Invalid email address");
      }
    }, 700);
  };
  const debouncedValidatePhone = useDebouncedCallback(
    // function
    (value) => {
      validatePhone(value);
    },
    // delay in ms
    100
  );

  const validatePhone = (value) => {
    let regex = /\d/g;
    setTimeout(() => {
      setPhoneError("");
      let result = value.match(regex);
      const valid = result && result.length === 11;
      if (valid || value === "") {
        setValidPhone(true);
      } else {
        setValidPhone(false);
        setPhoneError(
          "Invalid phone number. Phone number must contain 11 digits."
        );
      }
    }, 700);
  };

  return (
    <Modal
      title="Invite Admin"
      description="Invite a new admin to the vendor portal."
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={onInviteAdmin} className="flex flex-col gap-4">
        <InputControl
          label=" First Name"
          name="first-name"
          placeholder="Enter name"
          isRequired
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <InputControl
          label="Last Name"
          name="last-name"
          placeholder="Enter last name"
          isRequired
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <InputControl
          label="Email"
          type="email"
          name="admin-email"
          placeholder="test@info.com"
          errorMessage={emailError}
          isRequired
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
        />
        <InputControl
          label="Phone Number"
          name="admin-phone"
          placeholder="+1 403 123 4567"
          errorMessage={phoneError}
          isRequired
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
            debouncedValidatePhone(e.target.value);
          }}
        />

        <ErrorMessage message={inviteError} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              !email ||
              !firstName ||
              !lastName ||
              !phone ||
              !validPhone ||
              isLoading ||
              !validEmail
            }
            className={`btn-solid ${isLoading ? "loading" : ""}`}
          >
            {isLoading ? "Loading..." : "Invite Admin"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
