import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

const CustomDropdownMenu = ({ children, items, className }) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={`dropdown-menu-content ${className}`}
          sideOffset={5}
        >
          {items?.length > 0 &&
            items.map((item, index) => {
              if (item.label === "divider")
                return (
                  <DropdownMenu.Separator
                    className="h-[1px] bg-slate-100 m-1.5"
                    key={index}
                  />
                );
              return (
                <DropdownMenu.Item className="dropdown-menu-item" key={index}>
                  {item.render ? (
                    item.render()
                  ) : (
                    <button
                      type="button"
                      className="w-full text-start hover:text-primary-dark"
                      onClick={item.onClick}
                    >
                      {item.label}
                    </button>
                  )}
                </DropdownMenu.Item>
              );
            })}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default CustomDropdownMenu;
