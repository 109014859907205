import React from "react";
import CloseIcon from "../assets/svg/icon_close-circle.svg?react";

export default function ErrorMessage({ message, className }) {
  if (message)
    return (
      <div
        className={`flex items-start border-2 border-red-600/20 rounded-md bg-red-50 py-2 px-4 ${
          className || ""
        }`}
      >
        <CloseIcon className="h-6 w-6 text-red-600 mr-2 flex-shrink-0" />
        <p className="text-red-600">{message}</p>
      </div>
    );
}
