import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card";
import ErrorMessage from "../../components/ErrorMessage";
import BackButton from "../../components/BackButton";
import Tag from "../../components/Tag";
import {
  createProduct,
  getProductById,
  updateProduct,
} from "../../services/products";
import {
  capitalize,
  formatNumber,
  getErrorMessage,
} from "../../helpers/functions";
import InputControl, { InputLabel } from "../../components/InputControl";
import ImageUploader, { UploadPreview } from "../../components/ImageUploader";
import toast from "react-hot-toast";
import TrashIcon from "../../assets/svg/icon_trash.svg?react";
import CautionIcon from "../../assets/svg/icon_caution.svg?react";
import { PRODUCT_FILTER_LABEL } from "../../types/types";
import ProductErrorModal from "./modals/ProductErrorModal";
import DeleteProductModal from "./modals/DeleteProductModal";
import Select, { components } from "react-select";
import ChevronDownIcon from "../../assets/svg/icon_chevron-down.svg?react";
import {
  GENDER_LABEL,
  GENDER_TYPE,
  LIST_TYPE,
  genderOptions,
  sizingCategoryOptions,
  sizingCategoryTypeOptions,
} from "../../types/optionLists";

const NEW_PARAM = "new";
export default function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [error, setError] = useState("");
  const [saveError, setSaveError] = useState("");
  const [isCreatingProduct, setCreatingProduct] = useState(
    id === NEW_PARAM || false
  );
  const [productSku, setProductSku] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [groupId, setGroupId] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [gender, setGender] = useState();
  const [sizingCategory, setSizingCategory] = useState();
  const [sizingCategoryType, setSizingCategoryType] = useState();
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [showingProductErrorModal, setShowingProductErrorModal] =
    useState(false);
  const [isShowingDeleteConf, setShowingDeleteConf] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id === NEW_PARAM) {
      setCreatingProduct(true);
    } else {
      getProductDetails();
    }
  }, [id]);

  const updateFields = (data) => {
    setProduct(data);

    setTitle(data.title || "");
    setCategory(data.category || "");
    setProductSku(data.sku || "");
    setProductBrand(data.brand || "");
    setGroupId(data.itemGroupId || "");

    const attributes = data.productAttribute;
    setColor(attributes.color || "");
    setSize(attributes.size || "");

    setCurrentImageUrl(data.imageUrl);
    setSizingCategory(
      data.sizingCategory
        ? {
            label: capitalize(data.sizingCategory),
            value: data.sizingCategory.toLowerCase(),
          }
        : ""
    );
    setSizingCategoryType(
      data.sizingCategoryType
        ? {
            label: capitalize(data.sizingCategoryType),
            value: data.sizingCategoryType.toLowerCase(),
          }
        : ""
    );
    setGender(
      data.gender
        ? {
            label:
              data.gender === GENDER_TYPE.MALE
                ? GENDER_LABEL.MALE
                : GENDER_LABEL.FEMALE,
            value: data.gender.toLowerCase(),
          }
        : ""
    );
  };

  const getProductDetails = async () => {
    setLoading(true);
    setError("");

    await getProductById({ id })
      .then((res) => {
        if (res?.data) {
          updateFields(res.data);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const saveProductDetails = async () => {
    let productData = new FormData();
    const productBody = {
      sku: productSku,
      title,
      category,
      itemGroupId: groupId,
      productImage: fileList[0] || currentImageUrl,
      sizingCategory: sizingCategory?.value,
      sizingCategoryType: sizingCategoryType?.value,
      gender: gender?.value,
      brand: productBrand,
    };

    const attributes = {
      color,
      size,
    };

    for (let key in productBody) {
      productData.append(key, productBody[key]);
    }

    for (let key in attributes) {
      productData.append(`ProductAttribute.${key}`, attributes[key]);
    }

    setSaveError("");
    setSaving(true);

    if (isCreatingProduct) {
      await createProduct(productData)
        .then((res) => {
          if (res) {
            toast.success("Product created");
            navigate("/products");
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          setSaveError(message);
        })
        .finally(() => setSaving(false));
    } else {
      productData.append("Id", id);
      await updateProduct(productData)
        .then((res) => {
          if (res?.data) {
            updateFields(res.data);
            toast.success("Product updated");
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          setSaveError(message);
        })
        .finally(() => setSaving(false));
    }
  };

  function getCategoriesList(listType) {
    let list = genderOptions;
    if (listType === LIST_TYPE.SIZING_CAT) list = sizingCategoryOptions;
    if (listType === LIST_TYPE.SIZING_CAT_TYPE)
      list = sizingCategoryTypeOptions;
    return list.map((option) => {
      let label = option;
      if (option === GENDER_TYPE.MALE) label = GENDER_LABEL.MALE;
      if (option === GENDER_TYPE.FEMALE) label = GENDER_LABEL.FEMALE;
      return { value: option, label: capitalize(label) };
    });
  }

  const DropdownIndicator = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4 ml-2 text-[var(--clr-text)]" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <BackButton className="mr-2" defaultPath="/products" />
          <h1 className="page-title capitalize mb-0 mr-2">
            {isCreatingProduct
              ? "Create Product"
              : product?.productName || "Product Details"}
          </h1>
          {product && !product?.isAveaseCompatible ? (
            <button
              className="bg-av-orange-light p-1 rounded-full"
              onClick={() => setShowingProductErrorModal(true)}
              title="This product is missing required fields"
            >
              <CautionIcon className="w-6 h-6 text-orange-50" />
            </button>
          ) : (
            product && (
              <Tag status={PRODUCT_FILTER_LABEL.ACTIVE}>
                {PRODUCT_FILTER_LABEL.ACTIVE}
              </Tag>
            )
          )}
          {!product?.isAveaseCompatible && (
            <ProductErrorModal
              isOpen={showingProductErrorModal}
              data={product?.systemResponse}
              onClose={() => setShowingProductErrorModal(false)}
            />
          )}
        </div>
        <div className="flex items-center">
          {!isCreatingProduct && (
            <button
              className="btn-outline btn-error mr-2"
              disabled={isLoading || isSaving || !product}
              onClick={() => setShowingDeleteConf(true)}
            >
              <TrashIcon className="btn-prefix-icon text-av-orange" />
              Delete
            </button>
          )}
          <button
            className={`btn-solid ${isSaving ? "loading" : ""}`}
            disabled={
              !title ||
              !category ||
              !productSku ||
              !sizingCategory ||
              !sizingCategoryType ||
              !productBrand ||
              !gender ||
              (fileList.length === 0 && !currentImageUrl)
            }
            onClick={saveProductDetails}
          >
            {isSaving
              ? "Loading..."
              : isCreatingProduct
              ? "Create Product"
              : "Save"}
          </button>
        </div>
        {!isCreatingProduct && (
          <DeleteProductModal
            isOpen={isShowingDeleteConf}
            product={product}
            onClose={() => setShowingDeleteConf(false)}
            onSuccess={() => navigate("/products")}
          />
        )}
      </div>

      {!isLoading && error && <ErrorMessage message={error} className="mb-4" />}

      {!isSaving && saveError && (
        <ErrorMessage message={saveError} className="mb-4" />
      )}

      {!isLoading && (product || isCreatingProduct) && (
        <div className="grid md:grid-cols-[2fr_1fr] gap-10">
          <Card className="flex flex-col gap-4">
            <h2 className="page-subtitle">Details</h2>
            <div className="grid gap-4">
              <p className="font-medium text-lg">Product Identification</p>
              <InputControl
                label="Product Title"
                name="product-title"
                placeholder="Title"
                isRequired
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <InputControl
                label="Product Category"
                name="product-category"
                placeholder="Category"
                isRequired
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              />
              <InputControl
                label="Product Sku"
                name="product-sku"
                placeholder="Product Sku"
                isRequired
                value={productSku}
                onChange={(e) => {
                  setProductSku(e.target.value);
                }}
              />
              <InputControl
                label="Product Brand"
                name="product-brand"
                placeholder="Product Brand"
                isRequired
                value={productBrand}
                onChange={(e) => {
                  setProductBrand(e.target.value);
                }}
              />
              <InputControl
                label="Product Group Id"
                name="group-id"
                placeholder="Group Id"
                value={groupId}
                onChange={(e) => {
                  setGroupId(e.target.value);
                }}
              />
              <InputControl
                label="Color"
                name="product-color"
                placeholder="Color"
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
              />
              <InputControl
                label="Size"
                name="product-size"
                placeholder="Size"
                value={size}
                onChange={(e) => {
                  setSize(e.target.value);
                }}
              />
            </div>
            <hr />
            <div className="flex flex-col gap-4">
              <p className="font-medium text-lg">Product Sizing Details</p>
              <div className="flex flex-col">
                <InputLabel
                  label="Product Sizing Category"
                  id="product-sizing-cat"
                  isRequired
                />
                <Select
                  id="product-sizing-cat"
                  classNamePrefix="react-select"
                  className="custom-select"
                  placeholder="Select sizing category"
                  isSearchable={false}
                  options={getCategoriesList(LIST_TYPE.SIZING_CAT)}
                  value={sizingCategory || ""}
                  onChange={(data) => setSizingCategory(data)}
                  components={{ DropdownIndicator }}
                />
              </div>
              <div className="flex flex-col">
                <InputLabel
                  label="Product Sizing Type"
                  id="product-sizing-type"
                  isRequired
                />
                <Select
                  id="product-sizing-type"
                  classNamePrefix="react-select"
                  className="custom-select"
                  placeholder="Select sizing type"
                  isSearchable={false}
                  options={getCategoriesList(LIST_TYPE.SIZING_CAT_TYPE)}
                  value={sizingCategoryType || ""}
                  onChange={(data) => setSizingCategoryType(data)}
                  components={{ DropdownIndicator }}
                />
              </div>
              <div className="flex flex-col">
                <InputLabel
                  label="Product Intended Gender"
                  id="product-intended-gender"
                  isRequired
                />
                <Select
                  id="product-intended-gender"
                  classNamePrefix="react-select"
                  className="custom-select"
                  placeholder="Select intended gender"
                  isSearchable={false}
                  options={getCategoriesList(LIST_TYPE.GENDER)}
                  value={gender || ""}
                  onChange={(data) => setGender(data)}
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>

            <hr />

            <div className="flex">
              <p className="font-medium text-lg">Image</p>
              <span className="text-red-500 ml-1">*</span>
            </div>
            {currentImageUrl ? (
              <UploadPreview
                preview={currentImageUrl}
                onClose={() => setCurrentImageUrl("")}
              />
            ) : (
              <ImageUploader
                fileList={fileList}
                radiusStyle="rounded-md"
                onChange={(files) => setFileList(files)}
                maxFiles={1}
              />
            )}
          </Card>

          {!isCreatingProduct && (
            <Card className="flex flex-col gap-4 mb-auto">
              <h2 className="page-subtitle">Analytics</h2>
              <div>
                <h3>Try ons</h3>
                <p>{formatNumber(product?.tryOns)}</p>
              </div>
            </Card>
          )}
        </div>
      )}
    </div>
  );
}
