import React, { useState, useEffect } from "react";
import AuthLayout from "../../layout/AuthLayout";
import InputControl from "../../components/InputControl";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PasswordRule from "../../components/PasswordRule";
import { passwordValidationRules } from "../../types/regex";
import ErrorMessage from "../../components/ErrorMessage";
import { setAccountPassword } from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import { saveSession, useAvStore } from "../../store";
import { getProfile } from "../../services/vendor";

export default function VendorRegister() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [registerSession, setRegisterSession] = useState("");

  useEffect(() => {
    setRegisterSession(searchParams.get("session"));
  }, [searchParams]);

  const onSubmitStep = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    await setAccountPassword({
      email: searchParams.get("email"),
      session: registerSession,
      password: password.trim(),
      passwordConfirmation: confirmPassword.trim(),
    })
      .then((res) => {
        const loginInfo = res.data?.loginResponse;
        if (loginInfo) {
          saveSession({
            access_token: loginInfo.accessToken,
            refresh_token: loginInfo.refreshToken,
          });
          getVendorProfile();
          navigate("/");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getVendorProfile = async () => {
    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateRule = (rule) => {
    if (rule === "match") return password === confirmPassword;
    if (rule && passwordValidationRules[rule]) {
      return passwordValidationRules[rule].test(password);
    }
    return false;
  };

  const validatePassword = (value) => {
    if (value) {
      let results = Object.keys(passwordValidationRules).map((r) => {
        return {
          rule: r,
          valid: passwordValidationRules[r].test(value.toString()),
        };
      });
      setValid(results.every((v) => v.valid));
    }
  };

  return (
    <AuthLayout>
      <div>
        <h1 className="text-4xl font-bold mb-6">Register</h1>
      </div>

      {registerSession && (
        <form
          className="mt-10 flex flex-col gap-2"
          onSubmit={(e) => onSubmitStep(e)}
        >
          <InputControl
            label="Password"
            type="password"
            name="password"
            placeholder="Your password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
          />
          <InputControl
            label="Confirm Password"
            type="password"
            name="confirm-password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div>
            <PasswordRule
              isValid={validateRule("match")}
              text="Passwords match"
            />
            <PasswordRule
              isValid={validateRule("lowercase")}
              text="1 lowercase character"
            />
            <PasswordRule
              isValid={validateRule("uppercase")}
              text="1 uppercase character"
            />
            <PasswordRule
              isValid={validateRule("special")}
              text="1 special character"
            />
            <PasswordRule
              isValid={validateRule("length")}
              text="8 characters minimum"
            />
          </div>
          <ErrorMessage message={error} />
          <button
            type="submit"
            disabled={
              !password ||
              !confirmPassword ||
              password !== confirmPassword ||
              !isValid ||
              isLoading
            }
            className={`btn-solid mt-6 justify-center ${
              isLoading ? "loading" : ""
            }`}
          >
            {isLoading ? "Loading..." : "Register"}
          </button>
          <p className="text-center">
            Already have an account?{" "}
            <Link to="/signin" className="link">
              Sign in
            </Link>
          </p>
        </form>
      )}
      {!registerSession && (
        <div className="flex flex-col text-center lg:text-start gap-4">
          <p>Oops! Are you supposed to be here?</p>
          <p>Please return to signin and enter your temporary password.</p>
          <button
            className="btn-solid justify-center"
            onClick={() => navigate("/signin")}
          >
            Back to Signin
          </button>
        </div>
      )}
    </AuthLayout>
  );
}
