import React from "react";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";

export default function NeedHelpSection() {
  return (
    <Card className="flex flex-col gap-4">
      <h2 className="page-subtitle">Need Help?</h2>
      <p>
        For additional information please contact our integration team by
        sending a message via the{" "}
        <Link to="/send-feedback" className="link">
          Feedback page
        </Link>
        .
      </p>
    </Card>
  );
}
