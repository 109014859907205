import React, { useState } from "react";
import InputControl from "../../components/InputControl";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../layout/AuthLayout";
import { saveSession, useAvStore } from "../../store";
import { REGEX_VALIDATION } from "../../types/regex";
import ErrorMessage from "../../components/ErrorMessage";
import { getErrorMessage } from "../../helpers/functions";
import { login } from "../../services/authentication";
import { AUTH_CHALLENGE } from "../../types/types";
import { getProfile } from "../../services/vendor";
import MailIcon from "../../assets/svg/icon_mail.svg?react";
import LockIcon from "../../assets/svg/icon_lock.svg?react";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValid, setValid] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");

    await login({ email, password: password.trim() })
      .then((res) => {
        const loginInfo = res.data?.loginResponse;
        const challengeInfo = res.data?.challenge;
        if (loginInfo) {
          saveSession({
            access_token: loginInfo.accessToken,
            refresh_token: loginInfo.refreshToken,
          });
          getVendorProfile();
          navigate("/");
        } else if (challengeInfo) {
          if (challengeInfo.name === AUTH_CHALLENGE.NEW_PSWD) {
            navigate(
              `/register?email=${encodeURIComponent(email)}&session=${
                challengeInfo.parameters["SESSION"]
              }`
            );
          } else if (challengeInfo.name === AUTH_CHALLENGE.MFA) {
            navigate("/auth", { state: { email, password: password.trim() } });
          }
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getVendorProfile = async () => {
    await getProfile()
      .then((res) => {
        if (res) {
          useAvStore.getState().setUser(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateEmail = (value) => {
    let regex = REGEX_VALIDATION.EMAIL;

    setTimeout(() => {
      setEmailError("");
      let result = regex.test(value);
      if (result || value === "") {
        setValid(true);
      } else {
        setValid(false);
        setEmailError("Invalid email address");
      }
    }, 700);
  };

  return (
    <AuthLayout>
      <div>
        <h1 className="text-4xl lg:text-5xl font-semibold mb-6 text-center lg:text-start">
          Sign in
        </h1>
      </div>
      <form className="mt-10 flex flex-col gap-2" onSubmit={onSubmit}>
        <InputControl
          label="Email"
          type="email"
          name="email"
          prefixIcon={<MailIcon />}
          placeholder="vendor@info.com"
          errorMessage={emailError}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            validateEmail(e.target.value);
          }}
        />
        <div className="flex flex-col">
          <InputControl
            label="Password"
            type="password"
            prefixIcon={<LockIcon />}
            name="password"
            placeholder="Your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Link
            className="text-sm self-end mt-1 hover:text-primary-light text-primary hover:underline"
            to="/forgot-password"
          >
            Forgot password?
          </Link>
        </div>
        <ErrorMessage message={error} />
        <button
          type="submit"
          disabled={!email || !password || !isValid || isLoading}
          className={`btn-solid mt-6 justify-center ${
            isLoading ? "loading" : ""
          }`}
        >
          {isLoading ? "Loading..." : "Sign in"}
        </button>
      </form>
    </AuthLayout>
  );
}
