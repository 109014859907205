import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const getCountries = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.COUNTRIES}`,
    method: "get",
  });
};
