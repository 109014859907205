import React from "react";
import {
  FEEDBACK_STATUS,
  PRODUCT_FILTER_LABEL,
  USER_STATUS,
} from "../types/types";

export default function Tag({ status, children }) {
  const getTagStyles = (status) => {
    switch (status) {
      case PRODUCT_FILTER_LABEL.ACTIVE:
      case USER_STATUS.ACTIVE:
      case FEEDBACK_STATUS.RESOLVED:
        return "bg-primary-xlight text-primary";

      case PRODUCT_FILTER_LABEL.INACTIVE:
        return "bg-orange-100 text-orange-700";

      case USER_STATUS.DISABLED:
      case FEEDBACK_STATUS.DELETED:
        return "bg-red-100 text-red-700";

      default:
        return "bg-white-smoke/60 text-light-gray";
    }
  };
  return (
    <span className="bg-white rounded-3xl overflow-hidden flex-shrink-0">
      <div
        className={`${getTagStyles(
          status
        )} py-1 px-4 inline-block rounded-3xl font-medium text-sm capitalize shadow-sm`}
      >
        {!children ? status : children}
      </div>
    </span>
  );
}
