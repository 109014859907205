import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const inviteAdmin = (data, signal) => {
  return service({
    url: `${baseUrl}/${apiRoutes.REGISTER_ADMIN}`,
    data,
    method: "post",
    signal,
  });
};

export const login = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.LOGIN}`,
    data,
    method: "post",
  });
};

export const setAccountPassword = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.SET_PSWD}`,
    data,
    method: "post",
  });
};

export const changePassword = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.CHANGE_PSWD}`,
    data,
    method: "post",
  });
};

export const forgotPassword = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.FORGOT_PSWD}`,
    data,
    method: "post",
  });
};

export const resetPassword = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.RESET_PSWD}`,
    data,
    method: "post",
  });
};

export const resendForgotPasswordCode = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.RESEND_FORGOT_PSWD_CODE}`,
    data,
    method: "post",
  });
};

export const getRefreshToken = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.REFRESH_TOKEN}`,
    data,
    method: "post",
  });
};

export const mfaLogin = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.MFA_LOGIN}`,
    data,
    method: "post",
  });
};

export const resendMfaCode = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.RESEND_MFA_CODE}`,
    data,
    method: "post",
  });
};

export const toggleMfa = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.TOGGLE_MFA}`,
    method: "put",
  });
};

export const resendInvite = ({ id }) => {
  return service({
    url: `${baseUrl}/${apiRoutes.RESEND_INVITE}/${id}`,
    method: "put",
  });
};
