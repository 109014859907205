import React, { useState } from "react";
import Modal from "../../../components/Modal";
import toast from "react-hot-toast";
import { getErrorMessage } from "../../../helpers/functions";
import ErrorMessage from "../../../components/ErrorMessage";
import { deleteProduct } from "../../../services/products";

export default function DeleteProductModal({
  isOpen,
  product,
  onClose,
  onSuccess,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onDelete = async () => {
    setLoading(true);

    await deleteProduct({ id: product.id })
      .then((res) => {
        if (res) {
          toast.success("Deleted Product");
          onClose();
          onSuccess();
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal title="Delete Product" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <p>
          Are you sure you want to delete <strong>{product?.title}</strong> from
          the feed? Any items associated with this product will no longer be
          Avease Compatible.
        </p>

        <ErrorMessage message={error} />
        <div className="self-end items-center">
          <button type="button" className="btn-outline mr-2" onClick={onClose}>
            Close
          </button>
          <button
            disabled={isLoading}
            className={`btn-solid btn-red ${isLoading ? "loading" : ""}`}
            onClick={onDelete}
          >
            {isLoading ? "Loading..." : "Delete Product"}
          </button>
        </div>
      </div>
    </Modal>
  );
}
