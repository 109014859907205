import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "../assets/svg/icon_close.svg?react";
import ImageIcon from "../assets/svg/icon_photo.svg?react";
import toast from "react-hot-toast";
import { getMimeType } from "../helpers/functions";

export const UploadPreview = ({ preview, onClose, isRounded }) => {
  return (
    <div className="relative h-[200px] w-[200px]">
      <div
        className={`overflow-hidden ${
          isRounded ? "rounded-full" : "rounded-av"
        } h-full`}
      >
        <img
          src={preview}
          alt="uploaded file"
          className="w-full h-full object-cover"
        />
      </div>
      <button
        type="button"
        title="Remove image"
        className="absolute top-2 right-2 bg-white p-1 rounded-full text-primary shadow-sm border border-slate-100 hover:border-primary"
        onClick={onClose}
      >
        <CloseIcon className="w-5 h-5" />
      </button>
    </div>
  );
};
export default function ImageUploader({
  fileList,
  onChange,
  className,
  maxFileSize,
  maxFiles,
  acceptedTypes,
  isRounded,
}) {
  const [filePreviews, setFilePreviews] = useState([]);
  const fileInputRef = useRef();
  const formattedTypes = acceptedTypes.map((f) => getMimeType(f));

  useEffect(() => {
    if (fileList?.length === 0) {
      setFilePreviews([]);
    } else if (fileList) {
      if (filePreviews.length === 0) {
        // Reload preview
        const preview = URL.createObjectURL(fileList[0]);
        setFilePreviews([preview]);
      }
    }
  }, [fileList]);

  const uploadFile = (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      let tooBig = false;
      for (let i = 0; i < files.length; i++) {
        let fileSize = (files[i].size / 1024 / 1024).toFixed(4); // MB formula
        if (fileSize > maxFileSize) {
          tooBig = true;
        }
      }
      if (
        fileList.length >= maxFiles ||
        files.length > maxFiles ||
        files.length + fileList.length > maxFiles
      ) {
        toast.error(`You cannot upload more than ${maxFiles} files.`);
      } else if (tooBig) {
        toast.error(`Files must be ${maxFileSize}MB or less in size`);
      } else {
        let newFiles = [...fileList, ...files];
        let previews = [];
        for (let i = 0; i < newFiles.length; i++) {
          const preview = URL.createObjectURL(newFiles[i]);
          previews.push(preview);
        }

        setFilePreviews([...previews]);
        onChange(newFiles);
      }
    } else {
      toast.error("File upload failed.");
    }
    e.target.value = null;
  };

  const handleRemove = (index) => {
    let newPreviews = [...filePreviews];
    let newUploadedFiles = [...fileList];
    if (index > -1) {
      newPreviews.splice(index, 1);
      newUploadedFiles.splice(index, 1);
    }
    setFilePreviews(newPreviews);
    onChange(newUploadedFiles);
    fileInputRef.current.value = null;
  };

  return (
    <div className={`flex flex-col ${className || ""}`}>
      {fileList?.length < maxFiles && (
        <div className="h-full">
          <div
            className={`flex flex-col justify-center text-center text-sm slate-gray-500 items-center cursor-pointer border-2 border-white-smoke-dark border-dashed ${
              isRounded ? "rounded-full" : "rounded-av"
            } h-[200px] w-[200px] px-4`}
            onClick={() => {
              if (fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            <span className="bg-white-smoke p-2 rounded-2xl">
              <ImageIcon className="w-6 h-6 text-primary" />
            </span>
            <div className="font-medium">
              <p>Drag and drop your image here</p>
              <p>
                or <span className="text-primary">Browse</span>
              </p>
            </div>
            <p className="text-xs mt-2 text-av-gray-md px-2">
              Allowed formats: {acceptedTypes.join(", ")}
            </p>
          </div>
        </div>
      )}
      {filePreviews?.length > 0 && (
        <div>
          {filePreviews.map((preview, index) => {
            return (
              <UploadPreview
                preview={preview}
                isRounded={isRounded}
                key={index}
                onClose={() => handleRemove(index)}
              />
            );
          })}
        </div>
      )}

      <input
        type="file"
        id="file-uploader"
        multiple={maxFiles > 1}
        className="hidden"
        ref={fileInputRef}
        accept={formattedTypes.join(",")}
        onChange={uploadFile}
      />
    </div>
  );
}

ImageUploader.defaultProps = {
  maxFiles: 5,
  acceptedTypes: ["png", "jpeg"],
  maxFileSize: 5,
};
