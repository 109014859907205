import { create } from "zustand";

export const loadSession = () => {
  let authSession = localStorage.getItem("av-vendor-session");
  if (authSession) {
    try {
      return JSON.parse(authSession);
    } catch (error) {
      return;
    }
  }
};

export const saveSession = (session) => {
  localStorage.setItem("av-vendor-session", JSON.stringify(session));
};

export const clearSession = () => {
  localStorage.removeItem("av-vendor-session");
};
export const checkAuthentication = () => {
  const session = loadSession();

  return (
    session !== undefined &&
    session.access_token !== undefined &&
    session.refresh_token !== undefined
  );
};

const adminStore = (set) => ({
  isAuthenticated: checkAuthentication(),
  setUser: (userInfo) => {
    set(() => ({
      user: userInfo,
    }));
  },
});

export const useAvStore = create(adminStore);
