import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import ReactTable from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Tag from "../../components/Tag";
import SearchIcon from "../../assets/svg/icon_search.svg?react";
import PlusIcon from "../../assets/svg/icon_plus.svg?react";
import PhotoIcon from "../../assets/svg/icon_photo.svg?react";
import InputControl from "../../components/InputControl";
import * as Tabs from "@radix-ui/react-tabs";
import { getProducts } from "../../services/products";
import { formatNumber, getErrorMessage } from "../../helpers/functions";
import ErrorMessage from "../../components/ErrorMessage";
import { PRODUCT_FILTER_LABEL, PRODUCT_FILTER_STATUS } from "../../types/types";

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor((row) => row, {
    id: "product-details",
    cell: (info) => (
      <div className="flex items-center">
        <div className="w-16 h-16 aspect-square overflow-hidden mr-4 border rounded-md">
          {info.getValue().imageUrl ? (
            <img
              src={info.getValue().imageUrl}
              alt="product thumbnail"
              className="w-full h-full object-cover object-center"
            />
          ) : (
            <div
              className="h-full flex items-center justify-center bg-white-smoke"
              title="Image Missing"
            >
              <PhotoIcon className="text-light-gray h-8 w-8 opacity-80" />
            </div>
          )}
        </div>
        <Link
          to={`/products/${info.getValue().id}`}
          className="font-medium hover:underline cursor-pointer"
        >
          {info.getValue().title || "[Missing Name]"}
        </Link>
      </div>
    ),
    header: () => <span>Product</span>,
  }),

  columnHelper.accessor("tryOns", {
    header: () => <p className="text-center">Try Ons</p>,
    cell: (info) => (
      <p className="text-center">{formatNumber(info.renderValue())}</p>
    ),
  }),
  columnHelper.accessor("isAveaseCompatible", {
    header: "Status",
    cell: (info) => (
      <Tag
        status={
          info.renderValue()
            ? PRODUCT_FILTER_LABEL.ACTIVE
            : PRODUCT_FILTER_LABEL.INACTIVE
        }
      >
        {info.renderValue()
          ? PRODUCT_FILTER_LABEL.ACTIVE
          : PRODUCT_FILTER_LABEL.INACTIVE}
      </Tag>
    ),
  }),
];

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("searchBy") || ""
  );
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getProductsData();
  }, [searchParams]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (searchValue) {
        searchParams.set("searchBy", searchValue);
      } else {
        searchParams.delete("searchBy");
      }
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(t);
  }, [searchValue]);

  const getProductsData = async () => {
    setLoading(true);
    setError("");

    await getProducts({
      Page: searchParams.get("page") || 1,
      PerPage: 10,
      SearchBy: searchParams.get("searchBy") || "",
      ProductStatus:
        searchParams.get("tab") === PRODUCT_FILTER_LABEL.ACTIVE
          ? PRODUCT_FILTER_STATUS.COMPATIBLE
          : searchParams.get("tab") === PRODUCT_FILTER_LABEL.INACTIVE
          ? PRODUCT_FILTER_STATUS.INCOMPATIBLE
          : PRODUCT_FILTER_STATUS.ALL,
    })
      .then((res) => {
        if (res) {
          setProducts(res.data.items);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => setLoading(false));
  };

  const onTabSelect = (value) => {
    if (value === "all") {
      searchParams.delete("tab");
    } else {
      searchParams.set("tab", value);
    }
    searchParams.set("page", 1);
    setSearchParams(searchParams);
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-10">
        <h1 className="page-title mb-0">Product Feed</h1>
        <button
          type="button"
          className="btn-solid"
          onClick={() => navigate("/products/new")}
        >
          <PlusIcon className="btn-prefix-icon" />
          Add Product
        </button>
      </div>
      {!isLoading && error ? (
        <ErrorMessage message={error} />
      ) : (
        <Card>
          <Tabs.Root
            value={searchParams.get("tab") || PRODUCT_FILTER_LABEL.ALL}
            onValueChange={(value) => onTabSelect(value)}
          >
            <Tabs.List className="flex items-center mb-4">
              <Tabs.Trigger
                value="all"
                className="tab-trigger [&[data-state='active']]:underline"
              >
                All
              </Tabs.Trigger>
              <Tabs.Trigger
                value={PRODUCT_FILTER_LABEL.ACTIVE}
                className="tab-trigger [&[data-state='active']]:underline capitalize"
              >
                {PRODUCT_FILTER_LABEL.ACTIVE}
              </Tabs.Trigger>
              <Tabs.Trigger
                value={PRODUCT_FILTER_LABEL.INACTIVE}
                className="tab-trigger [&[data-state='active']]:underline capitalize"
              >
                {PRODUCT_FILTER_LABEL.INACTIVE}
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
          <InputControl
            type="search"
            name="search-bar"
            className="mb-4"
            clearable
            onClear={() => setSearchValue("")}
            placeholder="Search products..."
            prefixIcon={<SearchIcon className="prefix-icon" />}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <ReactTable
            columns={columns}
            data={products}
            isLoading={isLoading}
            totalPages={totalPages}
            emptyMessage="No products found..."
          />
        </Card>
      )}
    </div>
  );
}
