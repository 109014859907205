import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import ReactTable from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "../../assets/svg/icon_search.svg?react";
import TrashIcon from "../../assets/svg/icon_trash.svg?react";
import SendIcon from "../../assets/svg/icon_send.svg?react";
import PlusIcon from "../../assets/svg/icon_plus.svg?react";
import InputControl from "../../components/InputControl";
import InviteAdminModal from "./modals/InviteAdminModal";
import { getAdmins } from "../../services/team";
import RemoveAdminModal from "./modals/RemoveAdminModal";
import ErrorMessage from "../../components/ErrorMessage";
import { useAvStore } from "../../store";
import { ADMIN_STATUS, USER_ROLE } from "../../types/types";
import { resendInvite } from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import toast from "react-hot-toast";

const columnHelper = createColumnHelper();

export default function Team() {
  const user = useAvStore((state) => state.user);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isConfirmDeleteModalShowing, setConfirmDeleteModalShowing] =
    useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("searchBy") || ""
  );
  const [resendingInvite, setResendingInvite] = useState(false);

  const columns = [
    columnHelper.accessor((row) => row, {
      id: "Name",
      cell: (info) => (
        <div>
          <p className="font-medium">{`${info.getValue().firstName} ${
            info.getValue().lastName
          }`}</p>
          <p>{info.getValue().email}</p>
        </div>
      ),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("phoneNumber", {
      cell: (info) => info.getValue(),
      header: () => <span>Phone Number</span>,
    }),
    columnHelper.accessor("createdDate", {
      id: "createdDate",
      cell: (info) => (
        <div className="min-w-[100px]">
          {info.row.original.status === ADMIN_STATUS.PENDING ? (
            <p className="text-lighter italic text-sm">Invite Pending</p>
          ) : (
            <p className="font-medium">{info.getValue()}</p>
          )}
        </div>
      ),
      header: () => <span>Date Registered</span>,
    }),
    {
      id: "actions",
      cell: (info) => (
        <>
          {user?.role === USER_ROLE.ADMIN ? (
            ""
          ) : (
            <div className="grid grid-cols-2 gap-2 w-10">
              {info.row.original.status === ADMIN_STATUS.PENDING && (
                <button
                  className="disabled:text-slate-300"
                  title="Resend Invite"
                  disabled={
                    resendingInvite && selectedAdmin.id === info.row.original.id
                  }
                  onClick={() => {
                    onResendInvite(info.row.original.id);
                    setSelectedAdmin(info.row.original);
                  }}
                >
                  {info.row.original.email === user?.email ? (
                    ""
                  ) : (
                    <SendIcon className="w-5 h-5 hover:text-primary" />
                  )}
                </button>
              )}
              <button
                className="disabled:text-slate-300 col-start-2"
                title="Remove Admin"
                onClick={() => {
                  setConfirmDeleteModalShowing(true);
                  setSelectedAdmin(info.row.original);
                }}
              >
                {info.row.original.email === user.email ? (
                  ""
                ) : (
                  <TrashIcon className="w-5 h-5 hover:text-red-500" />
                )}
              </button>
            </div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getAdminsList();
  }, [searchParams]);

  useEffect(() => {
    let t = setTimeout(() => {
      if (searchValue) {
        searchParams.set("searchBy", searchValue);
      } else {
        searchParams.delete("searchBy");
      }
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(t);
  }, [searchValue]);

  const getAdminsList = async () => {
    setLoading(true);
    setError("");

    await getAdmins({
      Page: searchParams.get("page") || 1,
      PerPage: 10,
      SearchBy: searchParams.get("searchBy") || "",
    })
      .then((res) => {
        if (res) {
          setData(res.data.items);
          setTotalPages(res.data.totalPages);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onResendInvite = async (id) => {
    setResendingInvite(true);

    await resendInvite({ id })
      .then((res) => {
        if (res) {
          toast.success("Invite sent");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        toast.error(message);
      })
      .finally(() => {
        setResendingInvite(false);
        setSelectedAdmin(null);
      });
  };

  return (
    <div className="container">
      <div className="flex items-center justify-between mb-10">
        <h1 className="page-title mb-0">Team</h1>
        {user?.role !== USER_ROLE.ADMIN && (
          <button
            type="button"
            className="btn-solid"
            onClick={() => {
              setInviteModalOpen(true);
            }}
          >
            <PlusIcon className="btn-prefix-icon" />
            Invite Admin
          </button>
        )}
        <InviteAdminModal
          isOpen={isInviteModalOpen}
          onSuccess={getAdminsList}
          onClose={() => setInviteModalOpen(false)}
        />
      </div>

      {!isLoading && error ? (
        <ErrorMessage message={error} />
      ) : (
        <Card>
          <InputControl
            type="search"
            name="search-bar"
            className="mb-4"
            clearable
            onClear={() => setSearchValue("")}
            placeholder="Search admins..."
            prefixIcon={<SearchIcon className="prefix-icon" />}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />

          <ReactTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            totalPages={totalPages}
            emptyMessage="No admins found..."
          />
        </Card>
      )}
      {selectedAdmin && (
        <RemoveAdminModal
          isOpen={isConfirmDeleteModalShowing}
          onClose={() => {
            setConfirmDeleteModalShowing(false);
            setSelectedAdmin(null);
          }}
          onSuccess={() => getAdminsList()}
          selectedAdmin={selectedAdmin}
        />
      )}
    </div>
  );
}
