const USER_STATUS = {
  ACTIVE: "active",
  DISABLED: "disabled",
};

const AUTH_CHALLENGE = {
  NEW_PSWD: "NEW_PASSWORD_REQUIRED",
  MFA: "MFA",
};

const USER_ROLE = {
  ADMIN: "vendoradmin",
  VENDOR_SUPER: "vendorsuperadmin",
};

const ADMIN_STATUS = {
  ACTIVE: "active",
  BLOCKED: "blocked",
  PENDING: "FORCE_CHANGE_PASSWORD",
};

const TABLE_MEASUREMENTS = {
  CM: "cm",
  INCHES: "in",
};

const FEEDBACK_STATUS = {
  PENDING: "pending",
  RESOLVED: "resolved",
  DELETED: "deleted",
};

const PRODUCT_FILTER_STATUS = {
  ALL: "All",
  COMPATIBLE: "Compatible",
  INCOMPATIBLE: "InCompatible",
};
const PRODUCT_FILTER_LABEL = {
  ALL: "all",
  ACTIVE: "active",
  INACTIVE: "inactive",
};

const CURRENT_CHART_PARAM = "chart";

export {
  USER_STATUS,
  AUTH_CHALLENGE,
  USER_ROLE,
  ADMIN_STATUS,
  TABLE_MEASUREMENTS,
  FEEDBACK_STATUS,
  PRODUCT_FILTER_LABEL,
  PRODUCT_FILTER_STATUS,
  CURRENT_CHART_PARAM,
};
