import React from "react";
import Card from "../../components/Card";
import CopyIcon from "../../assets/svg/icon_copy.svg?react";
import { Link } from "react-router-dom";
import { useAvStore } from "../../store";
import widgetPreview from "../../assets/images/setup-images/widget-preview.png";
import GuideSection from "./guides/GuideSection";
import NeedHelpSection from "./guides/NeedHelpSection";
import toast from "react-hot-toast";

export default function WidgetIntegration() {
  const user = useAvStore((state) => state.user);
  const htmlSnippet = `<div data-widget-id="avease-widget-loc" data-product-sku={YOUR_PRODUCT_SKU}></div>`;
  const widgetScript = `<script>
(function (w, d, s, o, f, js, fjs) {
  w["Avease-Widget"] = o;
  w[o] =
  w[o] ||
  function () {
    (w[o].q = w[o].q || []).push(arguments);
  };
  (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
  js.id = o;
  js.src = f;
  js.async = 1;
  fjs.parentNode.insertBefore(js, fjs);
})(window,document,"script","av",
"https://widget-staging-avease.nexxtdevelopment.ca/assets/snippet.js"
);
av("config", {
  id: "${user?.widgetId || "YOUR_WIDGET_ID"}"
});
av("init");
</script>`;

  const CopyButton = ({ content, className, title, label }) => {
    return (
      <button
        title={title}
        className={`ml-2 hover:text-primary p-2 ${className || ""}`}
      >
        <CopyIcon
          className="w-4 h-4 mb-0.5"
          onClick={() => handleCopy(content, label)}
        />
      </button>
    );
  };

  const handleCopy = (code, label) => {
    if (code) {
      let copyInput = document.createElement("input");
      document.body.appendChild(copyInput);
      copyInput.setAttribute("value", code);
      copyInput.select();
      document.execCommand("Copy");
      toast.success(
        label ? `Copied ${label} to clipboard.` : "Copied to clipboard."
      );
      copyInput.remove();
    }
  };

  return (
    <div className="container">
      <h1 className="page-title">Widget Integration Guide</h1>
      <div className="flex flex-col gap-4 max-w-[820px] w-full">
        <Card className="flex flex-col gap-4">
          <p>
            The widget is your website's connection to the Avease App. It will
            determine whether to show the Avease Try On button, and allows your
            customers to try on your products through the app.
          </p>
          <p>Please follow the instructions below to get started.</p>

          <GuideSection title="1. Adding the script">
            <p>
              Integration will require adding a script with your personal widget
              key.
            </p>
            <p>
              This is your personal Widget Key:{" "}
              {user?.widgetId && (
                <span className="font-medium bg-slate-100 px-2 p-1 rounded-lg inline-flex items-center">
                  {user.widgetId}{" "}
                  <CopyButton
                    content={user.widgetId}
                    title="Copy ID"
                    label="Widget ID"
                  />
                </span>
              )}
            </p>
            <div>
              Add the following script to the{" "}
              <pre className="inline-block bg-slate-100 px-2 p-1 rounded-lg">
                <code>{`<body>`}</code>
              </pre>{" "}
              tag of your product page or the root of your application.
            </div>

            <div className="relative bg-slate-100 min-h-20 w-full rounded-md overflow-hidden">
              <div className="h-10 bg-slate-200"></div>
              <pre className="overflow-x-auto">
                <code className="grid">
                  <p className="p-4">{widgetScript}</p>
                  <CopyButton
                    className="absolute top-1 right-3"
                    title="Copy Code Block"
                    label="Widget Script"
                    content={widgetScript}
                  />
                </code>
              </pre>
            </div>
          </GuideSection>

          <GuideSection title="2. Setting up the embedded HTML snippet">
            <p>
              Embed the following code where you want the Avease try on button
              on your product page. Ensure that the product sku is added to the
              data-sku attribute.
            </p>
            <div className="relative bg-slate-100 min-h-20 w-full rounded-md overflow-hidden">
              <div className="h-10 bg-slate-200"></div>
              <pre className="overflow-x-auto">
                <code className="grid">
                  <p className="p-4">{htmlSnippet}</p>
                  <CopyButton
                    className="absolute top-1 right-3"
                    title="Copy Code Block"
                    label="HTML Snippet"
                    content={htmlSnippet}
                  />
                </code>
              </pre>
            </div>
          </GuideSection>

          <GuideSection title="3. Setup Complete">
            <p>
              To verify that the widget has been successfully integrated. Click
              on the 'Try On With Avease' button. An iframe will pop up and
              prompt you to sign in.
            </p>
            <p>
              If the button does not appear. Please ensure that the product you
              are viewing is active in the Product Feed.
            </p>
            <div>
              <img src={widgetPreview} alt="Sign in preview" />
            </div>
          </GuideSection>

          <hr />
          <GuideSection title="Integration with Specific Systems">
            {" "}
            <p>
              See the guides below for more information relevant to your
              platform.
            </p>
            <ul>
              <li>
                <Link to="/widget-integration/guides/shopify" className="link">
                  Shopify Guide
                </Link>
              </li>
            </ul>
          </GuideSection>
        </Card>
        <NeedHelpSection />
      </div>
    </div>
  );
}
