import React from "react";

export default function ScreenLoader({ title, className }) {
  return (
    <div
      className={`${
        className || ""
      } flex flex-col items-center justify-center gap-2 p-2`}
    >
      <div className="dots-loader">
        <div className="loader-dot first"></div>
        <div className="loader-dot second"></div>
        <div className="loader-dot third"></div>
      </div>
      {title && <p>{title}</p>}
    </div>
  );
}
