export const REGEX_VALIDATION = {
  EMAIL:
    /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
};

export const passwordValidationRules = {
  lowercase: new RegExp(/[a-z]/),
  uppercase: new RegExp(/[A-Z]/),
  number: new RegExp(/[0-9]/),
  special: new RegExp(/^(?=.*[\^$*.\[\]{}()?"!@#%&\/\\,><':;|_~`=+-])/),
  length: new RegExp(/.{8,}/),
};

export const PasswordRuleKeys = {
  Lowercase: Symbol("lowercase"),
  Uppercase: Symbol("uppercase"),
  Number: Symbol("number"),
  Special: Symbol("special"),
  Length: Symbol("length"),
};
