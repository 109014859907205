import React from "react";
import CheckIcon from "../assets/svg/icon_check-circle.svg?react";

const PasswordRule = ({ text, isValid }) => {
  return (
    <div className="flex items-center">
      <CheckIcon
        className={`w-6 h-6 mr-2 ${
          isValid ? "text-green-500" : "text-slate-500"
        }`}
      />
      <p className="text-sm text-slate-500">{text}</p>
    </div>
  );
};

export default PasswordRule;
