import React from "react";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import GuideSection from "./guides/GuideSection";

const requiredAttributes = [
  { name: "sku", description: "Product SKU" },
  { name: "title", description: "Product title" },
  { name: "brand", description: "Product brand" },
  { name: "image_link", description: "Product image url" },
  { name: "categories", description: "Product categories" },
  { name: "product_group_id", description: "Product variant group ID" },
  { name: "size", description: "Product size (ex. M, Small, XL)" },
  { name: "color", description: "Product color(s)" },
  { name: "gender*", description: "Product intended gender (m, f)" },
  {
    name: "sizing_category*",
    description: "Avease sizing category \n(top, bottom, fullbody, dress)",
  },
  {
    name: "sizing_category_type*",
    description: "Avease sizing type (short, long)",
  },
];

export default function ProductFeedGuide() {
  return (
    <div className="container">
      <h1 className="page-title">Feed Formatting Guide</h1>
      <Card className="flex flex-col gap-4 max-w-[820px] w-full">
        <GuideSection title="What is the Product Feed?">
          <p>
            The product feed is where you can see and manage the product data
            you have uploaded. Products can be uploaded in batches as a csv file
            in{" "}
            <Link to="/product-data-upload" className="link">
              Upload Product Data
            </Link>{" "}
            or individually via the{" "}
            <Link to="/products" className="link">
              Product Feed page
            </Link>
            .
          </p>
        </GuideSection>

        <GuideSection title="Data Retrieval">
          <p>
            For efficient data retrieval, format product data with the following
            attributes.
          </p>
          <div className="bg-slate-100 min-h-20 w-full rounded-md">
            <pre className="overflow-x-auto">
              <code className="grid grid-cols-2 m-4">
                <React.Fragment>
                  <div className="flex flex-col p-2 font-bold">
                    <p>Attribute Name</p>
                  </div>
                  <div className="flex flex-col p-2 font-bold">
                    <p>Description</p>
                  </div>
                </React.Fragment>
                {requiredAttributes.map((attr, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="flex flex-col border p-2">
                        <p>{attr.name}</p>
                      </div>
                      <div className="flex flex-col border p-2">
                        <p>{attr.description}</p>
                      </div>
                    </React.Fragment>
                  );
                })}
              </code>
            </pre>
          </div>
          <p>
            * These attributes are required in order to generate a fit
            recommendation.
          </p>
        </GuideSection>

        <hr />
        <GuideSection title="FAQs">
          <ol className="list-decimal list-inside flex flex-col gap-4">
            <div>
              <li>
                Do I need to format my product data if I'm using Google Merchant
                Center?
              </li>
              <p className="pl-4 italic">
                We support Google Merchant center, however you will still need
                to add the Avease-specific attributes to get a recommended fit.
              </p>
            </div>
            <div>
              <li>
                I have a large number of products that do not have the required
                formatting. Is there any easy way for me to update these?
              </li>
              <p className="pl-4 italic">
                In order to update them, you will have to manually edit the csv
                file or update each product individually from the Product Feed.
              </p>
            </div>
          </ol>
        </GuideSection>
      </Card>
    </div>
  );
}
