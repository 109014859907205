import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import CloseIcon from "../assets/svg/icon_close.svg?react";

export default function Modal({
  title,
  description,
  isOpen,
  children,
  onClose,
  className,
}) {
  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(opening) => {
        if (isOpen && !opening) {
          onClose();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black/20 fixed inset-0 animate-overlayShow" />
        <Dialog.Content className={`modal-content ${className || ""}`}>
          <Dialog.Close asChild className="absolute top-4 right-4">
            <button className="w-5 h-5">
              <CloseIcon />
            </button>
          </Dialog.Close>
          <div className="pb-4">
            <Dialog.Title className="font-semibold text-xl">
              {title}
            </Dialog.Title>
            <Dialog.Description>{description}</Dialog.Description>
          </div>
          <hr />
          {/* Modal content */}
          <div className="py-4 max-h-[60vh] overflow-y-auto pr-4">
            {children}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
