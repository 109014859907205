import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomeLayout from "./layout/HomeLayout";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/dashboard/Dashboard";
import SignIn from "./pages/auth/SignIn";
import ForgotPassword from "./pages/auth/ForgotPassword";
import VendorRegister from "./pages/auth/VendorRegister";
import MFA from "./pages/auth/MFA";
import Team from "./pages/team/Team";
import ResetPassword from "./pages/auth/ResetPassword";
import AdminSecuritySettings from "./pages/settings/AdminSecuritySettings";
import { checkAuthentication } from "./store";
import ProductsPage from "./pages/products/ProductsPage";
import SendFeedbackPage from "./pages/send-feedback/SendFeedbackPage";
import WidgetIntegration from "./pages/setup/WidgetIntegration";
import IntegrationGuide from "./pages/setup/guides/IntegrationGuide";
import ProductFeedGuide from "./pages/setup/ProductFeedGuide";
import Profile from "./pages/profile/Profile";
import ProductDetails from "./pages/products/ProductDetails";
import UploadProductDataPage from "./pages/products/UploadProductDataPage";
import ScreenLoader from "./layout/ScreenLoader";
import SizingCharts from "./pages/setup/SizingCharts";
import EditSizingChart from "./pages/setup/EditSizingChart";

export default function RoutesList() {
  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <ScreenLoader title="Loading the Avease Vendor Portal..." />
        </div>
      }
    >
      <Routes>
        <Route
          element={
            <ProtectedRoute>
              <HomeLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/:id" element={<ProductDetails />} />
          <Route path="/send-feedback" element={<SendFeedbackPage />} />
          <Route path="/widget-integration" element={<WidgetIntegration />} />
          <Route
            path="/widget-integration/guides/:id"
            element={<IntegrationGuide />}
          />
          <Route
            path="/product-feed-formatting"
            element={<ProductFeedGuide />}
          />
          <Route
            path="/product-data-upload"
            element={<UploadProductDataPage />}
          />
          <Route path="/sizing-charts" element={<SizingCharts />} />
          <Route path="/sizing-charts/:id" element={<EditSizingChart />} />
          <Route path="/team" element={<Team />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/security-settings"
            element={<AdminSecuritySettings />}
          />
        </Route>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/auth" element={<MFA />} />
        <Route path="/register" element={<VendorRegister />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

function ProtectedRoute({ children }) {
  const isAuthenticated = checkAuthentication();

  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }
  return children;
}
