import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const getAdmins = (params) => {
  return service({
    url: `${baseUrl}/${apiRoutes.TEAM}`,
    params,
    method: "get",
  });
};

export const deleteTeamMember = ({ id }) => {
  return service({
    url: `${baseUrl}/${apiRoutes.DELETE_TEAM_MEMBER}/${id}`,
    method: "delete",
  });
};
