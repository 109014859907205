import { apiRoutes } from "../types/api-routes";
import { baseUrl } from "../types/config";
import service from "./service";

export const getProfile = () => {
  return service({
    url: `${baseUrl}/${apiRoutes.PROFILE}`,
    method: "get",
  });
};

export const sendUpdateProfileRequest = (data, signal) => {
  return service({
    url: `${baseUrl}/${apiRoutes.PROFILE}`,
    data,
    method: "patch",
    signal,
  });
};

export const updateProfileInfo = (data) => {
  return service({
    url: `${baseUrl}/${apiRoutes.CONTACT}`,
    data,
    method: "put",
  });
};

export const markTutorialAsRead = (signal) => {
  return service({
    url: `${baseUrl}/${apiRoutes.TUTORIAL}`,
    method: "put",
    signal,
  });
};
