import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Select, { components } from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import ChevronDownIcon from "../../assets/svg/icon_chevron-down.svg?react";
import ReactTable from "../../components/ReactTable";
import { getDefaultSizingCharts } from "../../services/products";
import {
  formatChartData,
  formatClothingMeasurement,
  getChartLabel,
  getErrorMessage,
} from "../../helpers/functions";
import { CURRENT_CHART_PARAM, TABLE_MEASUREMENTS } from "../../types/types";
import { createColumnHelper } from "@tanstack/react-table";
import EditIcon from "../../assets/svg/icon_edit.svg?react";
import CustomSwitch from "../../components/CustomSwitch";
import EnableFitConf from "./modals/EnableFitConf";
import ErrorMessage from "../../components/ErrorMessage";

export default function SizingCharts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [chartsList, setChartsList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [columns, setColumns] = useState([]);
  const [currentChart, setCurrentChart] = useState();
  const chartParam = searchParams.get(CURRENT_CHART_PARAM);
  const [currentMeasurement, setCurrentMeasurement] = useState(
    TABLE_MEASUREMENTS.CM
  );
  const [chartData, setChartData] = useState();
  const navigate = useNavigate();
  const [useFitRecommendation, setUseFitRecommendation] = useState(false);
  const [isShowingEnableConf, setShowingEnableConf] = useState(false);

  useEffect(() => {
    getCharts();
  }, []);

  useEffect(() => {
    if (chartData) {
      const charts = chartData.items;
      const selectedChart = chartParam
        ? charts.find((chart) => chart.id === chartParam)
        : charts[0];
      setCurrentChart(selectedChart);
    }
  }, [chartParam]);

  useEffect(() => {
    if (currentChart) {
      const cols = getSizingChartColumns(currentChart);
      setColumns(cols);
    }
  }, [currentChart, currentMeasurement]);

  const getCharts = async () => {
    setLoading(true);
    setError("");
    await getDefaultSizingCharts()
      .then((res) => {
        if (res?.data) {
          const charts = res.data.items;

          const selectedChart = chartParam
            ? charts.find((chart) => chart.id === chartParam)
            : charts[0];

          const cols = getSizingChartColumns(selectedChart);
          setColumns(cols);
          setCurrentChart(selectedChart);

          setCurrentMeasurement(res.data.unit || TABLE_MEASUREMENTS.CM);
          setChartData(res.data);
          setUseFitRecommendation(res.data?.isFitEnabled);

          /* Get formatted categories for the table select field */
          let formattedCats = charts.map((chart) => {
            return { value: chart.id, label: getChartLabel(chart.id) };
          });
          setChartsList(formattedCats);
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function getSizingChartColumns(data) {
    const columnHelper = createColumnHelper();

    const baseColumns = [
      columnHelper.accessor("title", {
        header: () => <span>Title</span>,
        cell: (info) => (
          <p className="capitalize min-w-[60px]">{info.row.original.title}</p>
        ),
      }),
    ];

    const sizes = data?.sizes.map((size) => size.name);

    const formattedSizes = sizes.map((size, index) => {
      return columnHelper.accessor((row) => row, {
        id: `${size}-${index}`,
        cell: (info) => {
          const stats = info.row.original.measurements.find(
            (m) => m.size === size
          );

          return (
            <p className="text-sm min-w-[80px]">
              {formatClothingMeasurement({
                val: stats?.min,
                current: currentMeasurement,
                addUnits: true,
                originalMeasurement: chartData?.unit,
              })}
              -
              {formatClothingMeasurement({
                val: stats?.max,
                current: currentMeasurement,
                addUnits: true,
                originalMeasurement: chartData?.unit,
              }) || "-"}
            </p>
          );
        },
        header: () => <span>{size}</span>,
      });
    });
    return [...baseColumns, ...formattedSizes];
  }

  const onFilterChartSelect = (value) => {
    searchParams.set(CURRENT_CHART_PARAM, value);
    setSearchParams(searchParams);
  };

  const DropdownIndicator = ({ ...props }) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4 ml-2 text-[var(--clr-text)]" />
      </components.DropdownIndicator>
    );
  };

  const toggleMeasurement = () => {
    if (currentMeasurement === TABLE_MEASUREMENTS.INCHES) {
      setCurrentMeasurement(TABLE_MEASUREMENTS.CM);
    } else {
      setCurrentMeasurement(TABLE_MEASUREMENTS.INCHES);
    }
  };

  const onEditChart = () => {
    const param =
      searchParams.get(CURRENT_CHART_PARAM) ||
      (chartsList.length > 0 ? chartsList[0].value : "");
    navigate(
      `/sizing-charts/${param}?defaultMeasurement=${currentMeasurement}`
    );
  };

  const onFitRecommendationToggle = async () => {
    setShowingEnableConf(true);
  };

  return (
    <div className="container">
      <h1 className="page-title">Sizing Charts</h1>
      {chartData?.isFitEnabled === false && (
        <div className="flex flex-col bg-primary-light/10 border-primary rounded-md p-4 mb-2 gap-2">
          <p>
            Fit recommendation is currently <strong>disabled</strong>. To enable
            please use the toggle in the section below.{" "}
          </p>
        </div>
      )}
      <EnableFitConf
        isEdited={chartData?.isEdited}
        isFitEnabled={chartData?.isFitEnabled}
        isOpen={isShowingEnableConf}
        onClose={() => setShowingEnableConf(false)}
        onSuccess={() => getCharts(chartParam)}
      />
      <ErrorMessage message={error} className="mb-4" />
      <div className="flex flex-col gap-4">
        <Card className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h2 className="page-subtitle">Fit Recommendation</h2>
            {!isLoading && (
              <CustomSwitch
                name="fit-switch"
                title="Toggle Fit Recommendation"
                value={useFitRecommendation}
                onChange={onFitRecommendationToggle}
              />
            )}
          </div>

          <p>
            Sizing charts make it possible for Avease to provide a fit
            recommendation.
          </p>
          <p>
            Avease has its own default charts prepared, but for more accurate
            results to your products please edit according to your own sizing
            ranges.
          </p>
        </Card>

        {!isLoading && !error && (
          <Card>
            <div className="flex flex-col lg:flex-row items-center justify-between mb-6">
              <div>
                <p className="text-lg font-semibold mb-2 md:mb-0">
                  {chartsList?.find(
                    (option) => option.value === searchParams.get("chart")
                  )?.label || chartsList[0]?.label}
                  - Sizing Chart
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-2 md:gap-0 items-center">
                <button
                  className="grid grid-cols-2 bg-white-smoke p-2 rounded-av flex-shrink-0"
                  onClick={toggleMeasurement}
                >
                  <div
                    className={`${
                      currentMeasurement === TABLE_MEASUREMENTS.INCHES
                        ? "bg-primary text-white"
                        : ""
                    } p-1 rounded-av`}
                  >
                    in
                  </div>
                  <div
                    className={`${
                      currentMeasurement === TABLE_MEASUREMENTS.CM
                        ? "bg-primary text-white"
                        : ""
                    } p-1 rounded-av`}
                  >
                    cm
                  </div>
                </button>
                <div className="flex items-center">
                  <Select
                    classNamePrefix="react-select"
                    className="custom-select min-w-[200px] max-w-[220px] mx-3"
                    placeholder="Select category"
                    isSearchable={false}
                    options={chartsList}
                    value={
                      chartsList?.find(
                        (option) =>
                          option.value === searchParams.get(CURRENT_CHART_PARAM)
                      ) || chartsList[0]
                    }
                    onChange={(data) => onFilterChartSelect(data.value)}
                    components={{ DropdownIndicator }}
                  />

                  <button
                    type="button"
                    title="Edit Chart"
                    onClick={onEditChart}
                  >
                    <EditIcon className="w-7 h-7 text-primary flex-shrink-0" />
                  </button>
                </div>
              </div>
            </div>
            <ReactTable
              columns={columns}
              data={formatChartData(currentChart)}
              isLoading={isLoading}
              totalPages={1}
              emptyMessage="No chart data found..."
            />
          </Card>
        )}
      </div>
    </div>
  );
}
