import React, { useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import InputControl from "../../components/InputControl";
import { REGEX_VALIDATION } from "../../types/regex";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { forgotPassword } from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import ErrorMessage from "../../components/ErrorMessage";
import MailIcon from "../../assets/svg/icon_mail.svg?react";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validateEmail = (value) => {
    let regex = REGEX_VALIDATION.EMAIL;

    setTimeout(() => {
      setEmailError("");
      let result = regex.test(value);
      if (result || value === "") {
        setValid(true);
      } else {
        setValid(false);
        setEmailError("Invalid email address");
      }
    }, 700);
  };

  const onContinue = async () => {
    setLoading(true);
    setError("");
    await forgotPassword({ email })
      .then(() => {
        navigate(`/reset-password?email=${encodeURIComponent(email)}`);
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AuthLayout>
      <div className="relative text-center lg:text-start">
        <BackButton className="absolute top-2 -left-6" defaultPath="/signin" />
        <h1 className="text-4xl lg:text-5xl font-semibold mb-6 text-center lg:text-start ml-3">
          Forgot Password
        </h1>
        <div className="flex flex-col gap-4">
          <p>
            Enter your email below. You will receive an email for resetting your
            password.
          </p>
          <InputControl
            label="Email"
            type="email"
            name="forgot-pswd"
            prefixIcon={<MailIcon />}
            placeholder="email@info.com"
            className="text-start"
            errorMessage={emailError}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value);
            }}
          />
          <ErrorMessage message={error} />
          <button
            className={`btn-solid justify-center ${isLoading ? "loading" : ""}`}
            disabled={!email || !isValid || isLoading}
            onClick={onContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </AuthLayout>
  );
}
