import React, { useState, useEffect } from "react";
import AuthLayout from "../../layout/AuthLayout";
import InputControl from "../../components/InputControl";
import { passwordValidationRules } from "../../types/regex";
import { useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import PasswordRule from "../../components/PasswordRule";
import {
  resendForgotPasswordCode,
  resetPassword,
} from "../../services/authentication";
import { getErrorMessage } from "../../helpers/functions";
import toast from "react-hot-toast";
import ErrorMessage from "../../components/ErrorMessage";

export default function ResetPassword() {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [isValid, setValid] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [step, setStep] = useState("reset-pswd"); //reset-pswd, success

  useEffect(() => {
    setEmail(searchParams.get("email"));
  }, [searchParams]);

  const validatePassword = (value) => {
    if (value) {
      let results = Object.keys(passwordValidationRules).map((r) => {
        return {
          rule: r,
          valid: passwordValidationRules[r].test(value.toString()),
        };
      });
      setValid(results.every((v) => v.valid));
    }
  };
  const onResetPassword = async (e) => {
    e.preventDefault();

    setLoading(true);
    setError("");

    await resetPassword({
      email,
      code: verificationCode,
      password: password.trim(),
      passwordConfirmation: confirmPassword.trim(),
    })
      .then((res) => {
        if (res) {
          setStep("success");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onResendClick = async () => {
    setLoading(true);
    setError("");

    await resendForgotPasswordCode({
      email,
    })
      .then((res) => {
        if (res) {
          toast.success("Code sent");
        }
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validateRule = (rule) => {
    if (rule === "match") return password === confirmPassword;
    if (rule && passwordValidationRules[rule]) {
      return passwordValidationRules[rule].test(password);
    }
    return false;
  };

  return (
    <AuthLayout>
      <div className="relative">
        <BackButton className="absolute top-2 -left-6" defaultPath="/signin" />
        <h1 className="text-4xl lg:text-5xl font-semibold mb-6 text-center lg:text-start ml-3">
          {step === "success" ? "Password Reset" : "Reset Password"}
        </h1>
        {step === "reset-pswd" && (
          <form onSubmit={onResetPassword} className="flex flex-col gap-4">
            <p>
              Enter the code you received in your email and complete the
              following password fields.
            </p>
            <InputControl
              label="Verification Code"
              name="reset-code"
              maxLength={6}
              placeholder="Enter your code here"
              className="text-start"
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
            />
            <InputControl
              label="Password"
              name="password"
              type="password"
              placeholder="New password"
              className="text-start"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
              }}
            />
            <InputControl
              label="Confirm Password"
              name="confirm-password"
              type="password"
              placeholder="Confirm password"
              className="text-start"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <div>
              <PasswordRule
                isValid={validateRule("match")}
                text="Passwords match"
              />
              <PasswordRule
                isValid={validateRule("lowercase")}
                text="1 lowercase character"
              />
              <PasswordRule
                isValid={validateRule("uppercase")}
                text="1 uppercase character"
              />
              <PasswordRule
                isValid={validateRule("special")}
                text="1 special character"
              />
              <PasswordRule
                isValid={validateRule("length")}
                text="8 characters minimum"
              />
            </div>
            <ErrorMessage message={error} />
            <button
              type="submit"
              className={`btn-solid justify-center ${
                isLoading ? "loading" : ""
              }`}
              disabled={
                !verificationCode ||
                !password ||
                !confirmPassword ||
                password !== confirmPassword ||
                !isValid ||
                isLoading
              }
            >
              {isLoading ? "Loading..." : "Reset Password"}
            </button>
            <button className="link" type="button" onClick={onResendClick}>
              Resend Verification Code
            </button>
          </form>
        )}
        {step === "success" && (
          <div className="flex flex-col text-center">
            <p>
              Your password has been successfully reset. Please sign in again to
              continue to the Vendor Portal.
            </p>
            <button
              type="button"
              className="btn-solid mt-6 justify-center"
              onClick={() => navigate("/signin")}
            >
              Sign in
            </button>
          </div>
        )}
      </div>
    </AuthLayout>
  );
}
